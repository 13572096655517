"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class OnboardingApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Create new Agency
         */
        this.createAgency = ({ agency }) => {
            runtime_1.throwIfNullOrUndefined(agency, 'createAgency');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/onboarding/agency',
                method: 'POST',
                headers,
                body: agency,
            });
        };
        /**
         * Create new AgencyManager
         */
        this.createAgencyManager = ({ agencyManager }) => {
            runtime_1.throwIfNullOrUndefined(agencyManager, 'createAgencyManager');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/onboarding/agencyManager',
                method: 'POST',
                headers,
                body: agencyManager,
            });
        };
        /**
         * Get a list of agencies
         */
        this.listAgencies = () => {
            return this.request({
                path: '/onboarding/agencies',
                method: 'GET',
            });
        };
    }
}
exports.OnboardingApi = OnboardingApi;
