"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ActivityType;
(function (ActivityType) {
    ActivityType["MERCHANT"] = "MERCHANT";
    ActivityType["CUSTOMER"] = "CUSTOMER";
    ActivityType["PRODUCTIONSOLUTION"] = "PRODUCTION_SOLUTION";
    ActivityType["SANDBOXSOLUTION"] = "SANDBOX_SOLUTION";
    ActivityType["GATEWAY"] = "GATEWAY";
})(ActivityType = exports.ActivityType || (exports.ActivityType = {}));
