"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGatewayApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class PaymentGatewayApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Relates merchant with a gateway via a PaymentGateway entity in the payment engine
         */
        this.createPaymentGateway = ({ paymentGatewayRelation }) => {
            runtime_1.throwIfNullOrUndefined(paymentGatewayRelation, 'createPaymentGateway');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/paymentGateway',
                method: 'POST',
                headers,
                body: paymentGatewayRelation,
            });
        };
        /**
         * Retrieves a PaymentGateway of the specified solution type
         */
        this.getPaymentGateway = ({ paymentGatewayGuid, solutionType }) => {
            runtime_1.throwIfNullOrUndefined(paymentGatewayGuid, 'getPaymentGateway');
            runtime_1.throwIfNullOrUndefined(solutionType, 'getPaymentGateway');
            const query = {
                'solutionType': solutionType,
            };
            return this.request({
                path: '/paymentGateway/{paymentGatewayGuid}'.replace('{paymentGatewayGuid}', runtime_1.encodeURI(paymentGatewayGuid)),
                method: 'GET',
                query,
            });
        };
        /**
         * Updates an existing PaymentGateway relation
         */
        this.updatePaymentGateway = ({ paymentGatewayGuid, paymentGatewayUpdate }) => {
            runtime_1.throwIfNullOrUndefined(paymentGatewayGuid, 'updatePaymentGateway');
            runtime_1.throwIfNullOrUndefined(paymentGatewayUpdate, 'updatePaymentGateway');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/paymentGateway/{paymentGatewayGuid}'.replace('{paymentGatewayGuid}', runtime_1.encodeURI(paymentGatewayGuid)),
                method: 'PUT',
                headers,
                body: paymentGatewayUpdate,
            });
        };
    }
}
exports.PaymentGatewayApi = PaymentGatewayApi;
