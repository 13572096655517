"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndpointsApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class EndpointsApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Adds a new endpoint
         */
        this.createEndpoint = ({ endpoint }) => {
            runtime_1.throwIfNullOrUndefined(endpoint, 'createEndpoint');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/endpoints',
                method: 'POST',
                headers,
                body: endpoint,
            });
        };
        /**
         * Deletes an endpoint
         */
        this.deleteEndpoint = ({ endpointGuid }) => {
            runtime_1.throwIfNullOrUndefined(endpointGuid, 'deleteEndpoint');
            return this.request({
                path: '/endpoints/{endpointGuid}'.replace('{endpointGuid}', runtime_1.encodeURI(endpointGuid)),
                method: 'DELETE',
            });
        };
        /**
         * Gets a list of endpoints
         */
        this.getEndpointsList = () => {
            return this.request({
                path: '/endpoints',
                method: 'GET',
            });
        };
        /**
         * Adds a new endpoint
         */
        this.updateEndpoint = ({ endpointGuid, endpoint }) => {
            runtime_1.throwIfNullOrUndefined(endpointGuid, 'updateEndpoint');
            runtime_1.throwIfNullOrUndefined(endpoint, 'updateEndpoint');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/endpoints/{endpointGuid}'.replace('{endpointGuid}', runtime_1.encodeURI(endpointGuid)),
                method: 'PUT',
                headers,
                body: endpoint,
            });
        };
    }
}
exports.EndpointsApi = EndpointsApi;
