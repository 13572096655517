
import { Component, Emit, Vue } from "vue-property-decorator";
import Modal from "@/components/shared/layout/Modal.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  Agency,
  OnboardingApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { SolutionType } from "@fundraisingbureauet/admin-typescript-client/models";
import { eventHub } from "@/router";

extend("required", required);

@Component({
  components: { Modal, ValidationProvider, ValidationObserver },
})
export default class AgencyDialog extends Vue {
  solutionType = SolutionType.SANDBOX;
  agencyName = "";
  agencyId = "";
  userEmail = "";
  userName = "";

  addAgencyWithManager(validate: () => Promise<any>) {
    validate().then(() => {
      const api = new OnboardingApi(AuthInterceptor.Instance);

      //  Create agency
      api
        .createAgency({
          agency: {
            solutionType: this.solutionType.toUpperCase() as SolutionType, // convert eNum to uppercase string
            agencyName: this.agencyName,
            agencyId: this.agencyId,
          },
        })
        .subscribe(
          (value) => {
            //  Create manager
            api
              .createAgencyManager({
                agencyManager: {
                  solutionType: this.solutionType.toUpperCase() as SolutionType, // convert eNum to uppercase string
                  agencyId: this.agencyId,
                  name: this.userName,
                  email: this.userEmail,
                },
              })
              .subscribe(
                (value) => {
                  this.$emit("added");
                },
                (error) => {
                  eventHub.$emit("httpError", error);
                }
              );
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    });
  }

  @Emit()
  added(agency: Agency) {
    // ?
  }

  @Emit()
  closed(event: any) {
    this.$emit("closed");
  }
}
