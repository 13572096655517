
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Activity } from "@fundraisingbureauet/admin-typescript-client/models/Activity";

@Component({ components: { ElementList } })
export default class MerchantHistory extends Vue {
  @Prop({ required: true, default: [] })
  activities!: Activity;

  columns: ListColumn<Activity>[] = [
    {
      key: "created",
      field: "createdTs",
      //  render: (activity) => {
      //    const $options = this.$options;
      //    const filters = $options.filters;
      //    return filters?.formatDate(activity.createdTs);
      //  },
    },
    {
      key: "performedBy",
      field: "performedBy",
    },
    {
      key: "type",
      //field: "type",
      render:  (item) => item.type + ": " + item.action
    },


  ];
}
