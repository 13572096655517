
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ModalToolbar from "@/components/shared/layout/ModalToolbar.vue";
import { DialogService } from "@/components/shared/layout/DialogService";

export interface ModalToolbarAction {
  name: string;
}

export interface ModalToolbarSettings {
  toolbarTitle: string;
  actions: ModalToolbarAction[];
  bgColor?: string;
}

@Component({
  components: { ModalToolbar },
})
export default class Modal extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ required: false, default: false })
  subModal!: boolean | null;

  @Prop({ required: false, default: false })
  subSubModal!: boolean | null;

  @Prop({ required: false, default: true })
  toolbar!: boolean;

  @Prop({ required: false, default: null })
  toolbarSettings!: ModalToolbarSettings | null;

  @Emit()
  closeModal() {
    //  Emit to parent
    console.log("Modal.vue: closeModal");
    this.$emit("close-modal");
  }

  @Emit()
  toolbarActionClicked(action: string) {
    //  Noop
  }

  mounted() {
    const div = this.$refs.modalDiv as HTMLDivElement;
    DialogService.addDialog(div);
    div.focus();
  }

  closeOnEscape(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closeModal();
    }
  }
}
