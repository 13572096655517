"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class SystemApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Creates a system merchant in the specified system and solution
         */
        this.createSystemMerchant = ({ systemType, systemMerchant }) => {
            runtime_1.throwIfNullOrUndefined(systemType, 'createSystemMerchant');
            runtime_1.throwIfNullOrUndefined(systemMerchant, 'createSystemMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/system/{systemType}'.replace('{systemType}', runtime_1.encodeURI(systemType)),
                method: 'POST',
                headers,
                body: systemMerchant,
            });
        };
        /**
         * Updates a system merchant in the specified system and solution
         */
        this.updateSystemMerchant = ({ systemType, id, systemMerchant }) => {
            runtime_1.throwIfNullOrUndefined(systemType, 'updateSystemMerchant');
            runtime_1.throwIfNullOrUndefined(id, 'updateSystemMerchant');
            runtime_1.throwIfNullOrUndefined(systemMerchant, 'updateSystemMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/system/{systemType}/merchant/{id}'.replace('{systemType}', runtime_1.encodeURI(systemType)).replace('{id}', runtime_1.encodeURI(id)),
                method: 'PUT',
                headers,
                body: systemMerchant,
            });
        };
    }
}
exports.SystemApi = SystemApi;
