"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceLineApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class InvoiceLineApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Create a InvoiceLine.
         */
        this.createInvoiceLine = ({ createInvoiceLine }) => {
            runtime_1.throwIfNullOrUndefined(createInvoiceLine, 'createInvoiceLine');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/invoiceLine',
                method: 'POST',
                headers,
                body: createInvoiceLine,
            });
        };
        /**
         * Delete a manually created InvoiceLine.
         */
        this.deleteManualInvoiceLine = ({ invoiceLineId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceLineId, 'deleteManualInvoiceLine');
            return this.request({
                path: '/invoiceLine/{invoiceLineId}'.replace('{invoiceLineId}', runtime_1.encodeURI(invoiceLineId)),
                method: 'DELETE',
            });
        };
        /**
         * Excludes the InvoiceLine from an existing, uninvoiced, InvoiceDraft. Sets the state to EXCLUDED and clears the invoiceDraftId.
         */
        this.excludeInvoiceLineFromDraft = ({ invoiceLineId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceLineId, 'excludeInvoiceLineFromDraft');
            return this.request({
                path: '/invoiceLine/{invoiceLineId}/exclude'.replace('{invoiceLineId}', runtime_1.encodeURI(invoiceLineId)),
                method: 'POST',
            });
        };
        /**
         * Retrieve a specific InvoiceLine.
         */
        this.getInvoiceLine = ({ invoiceLineId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceLineId, 'getInvoiceLine');
            return this.request({
                path: '/invoiceLine/{invoiceLineId}'.replace('{invoiceLineId}', runtime_1.encodeURI(invoiceLineId)),
                method: 'GET',
            });
        };
        /**
         * Include the InvoiceLine in an existing, uninvoiced, InvoiceDraft. Sets the state to IN_DRAFT and relates line to draft.
         */
        this.includeInvoiceLineInDraft = ({ invoiceLineId, invoiceDraftId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceLineId, 'includeInvoiceLineInDraft');
            runtime_1.throwIfNullOrUndefined(invoiceDraftId, 'includeInvoiceLineInDraft');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/invoiceLine/{invoiceLineId}/include'.replace('{invoiceLineId}', runtime_1.encodeURI(invoiceLineId)),
                method: 'POST',
                headers,
                body: invoiceDraftId,
            });
        };
    }
}
exports.InvoiceLineApi = InvoiceLineApi;
