
import NavigationTop from "@/components/shared/layout/NavigationTop.vue";
import NavigationLeft from "@/components/shared/layout/NavigationLeft.vue";
import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/router";
import { ValidationError } from "@fundraisingbureauet/admin-typescript-client/models/ValidationError";

import VueToast, { ToastPosition, ToastType } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
Vue.use(VueToast);

@Component({
  components: { NavigationTop, NavigationLeft },
})
export default class Navigation extends Vue {
  //  Initialise
  mounted() {
    eventHub.$on("httpError", (error: any) => {
      //  Log
      console.log(`error event: ${error}`);

      //  Toast
      if (error.status == 500) {
        this.errorToast(error.response.message);
      } else if (error.status == 400) {
        const validationErrors = error.response.validationErrors;
        validationErrors.forEach(
          (error: ValidationError) => this.errorToast(`${error.message}`)
          //  this.warningToast(`${error.fieldName}: ${error.message}`)
        );
      }
    });
    eventHub.$on("errorToast", (message: string) => {
      this.errorToast(message);
    });
    eventHub.$on("warningToast", (message: string) => {
      this.warningToast(message);
    });
    eventHub.$on("successToast", (message: string) => {
      this.successToast(message);
    });
  }

  //  Functions
  errorToast(message: string) {
    this.createToast(message, "error");
  }

  warningToast(message: string) {
    this.createToast(message, "warning");
  }

  successToast(message: string) {
    this.createToast(message, "success");
  }

  createToast(message: string, type: "warning" | "error" | "success") {
    const id: number = Math.random();
    this.$toast.open({
      message: message,
      type: type,
      position: "top-right",
      duration: 10000,
      dismissible: true,
    });
  }

  removeToast() {
    this.$toast.clear();
  }

  //  Log out
  logout() {
    localStorage.removeItem("token");

    //  Log
    console.log("Router push - logout");

    this.$router.push({ name: "Login" });
  }
}
