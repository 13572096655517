
import Modal from "@/components/shared/layout/Modal.vue";
import MerchantForm from "@/components/merchants/MerchantForm.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { ModalToolbarSettings } from "@/components/shared/layout/Modal.vue";

@Component({ components: { MerchantForm, Modal } })
export default class MerchantModalUpdate extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  toolbarSettings: ModalToolbarSettings | null = null;

  /**
   * Initialise
   */
  mounted() {
    this.toolbarSettings = {
      toolbarTitle: this.merchant.name,
      actions: [],
    };
  }

  private closeModal() {
    //  Emit to merchant modal
    this.$emit("stop-editing-merchant");
  }

  private merchantUpdated(merchant: Merchant) {
    //  Emit to merchant modal
    this.$emit("merchant-updated", merchant);
  }

  @Emit()
  toolbarActionClicked(action: string) {
    //  Noop
  }
}
