"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class GatewayApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieves a list of configured PaymentGateways for a specified gateway and GatewayMerchant
         */
        this.getPaymentGatewaysOfGatewayMerchant = ({ gatewayType, gatewayMerchantId }) => {
            runtime_1.throwIfNullOrUndefined(gatewayType, 'getPaymentGatewaysOfGatewayMerchant');
            runtime_1.throwIfNullOrUndefined(gatewayMerchantId, 'getPaymentGatewaysOfGatewayMerchant');
            return this.request({
                path: '/gateway/{gatewayType}/{gatewayMerchantId}/paymentGateways'.replace('{gatewayType}', runtime_1.encodeURI(gatewayType)).replace('{gatewayMerchantId}', runtime_1.encodeURI(gatewayMerchantId)),
                method: 'GET',
            });
        };
    }
}
exports.GatewayApi = GatewayApi;
