
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { LogLine } from "@fundraisingbureauet/admin-typescript-client";
import InvoiceLogLine from "@/components/invoice/InvoiceLogLine.vue";

@Component({
  components: {
    InvoiceLogLine,
  },
})
export default class InvoiceLogComponent extends Vue {
  @Prop({ required: true }) logLines!: LogLine[];
  @Prop({ required: true }) header?: string;
  @Prop({ required: true }) invoiceLogLoading?: boolean;
  @Prop({ required: true }) invoiceLogLoaded?: boolean;
  @Prop({ required: true }) invoiceLogErrored?: boolean;
  @Prop({ required: true }) logLinesTotalLength!: number;
  @Prop({ required: false, default: false }) isErrors?: boolean;

  get getLogLinesTotalLength() {
    if (this.logLinesTotalLength) {
      return this.logLinesTotalLength;
    }
    return 0;
  }

  get logLinesLength() {
    if (this.logLines) {
      return this.logLines.length;
    }

    return 0;
  }

  @Emit()
  correctInvoiceLog(logLineId: number) {
    this.$emit("correctInvoiceLog", logLineId);
  }
}
