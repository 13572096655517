
import Modal from "@/components/shared/layout/Modal.vue";
import GatewayModal from "@/components/gateways/GatewayModal.vue";
import gatewayTypeToUserText from "@/components/gateways/GatewayUtil";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import {
  GatewayApi,
  GatewaysApi,
  PaymentGatewayApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { GatewayPaymentGateways } from "@fundraisingbureauet/admin-typescript-client/models/GatewayPaymentGateways";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { GatewayType } from "@fundraisingbureauet/admin-typescript-client/models/GatewayType";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";
import { eventHub } from "@/router";

@Component({ components: { Modal, GatewayModal } })
export default class MerchantSolutionGatewayModal extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solution!: Solution;

  @Prop({ required: false, default: null })
  gatewayMerchant!: GatewayMerchant;

  gatewayMerchants: GatewayMerchant[] = [];
  locatedGatewayMerchant: GatewayMerchant | null = null;

  gatewayPaymentGateways: GatewayPaymentGateways | null = null;

  locatedGatewayMerchantId = null;
  registeringGatewayMerchant = false;

  /**
   * Initialise
   */
  created() {
    this.getGatewayMerchants();
  }

  private getGatewayMerchants() {
    this.gatewayMerchants = [];

    if (this.gatewayMerchant.gatewayType) {
      new GatewaysApi(AuthInterceptor.Instance)
        .getGatewayMerchants({
          gatewayType:
            this.gatewayMerchant.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string
        })
        .subscribe(
          (response) => {
            this.gatewayMerchants = response;
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Detect where gateway might be used al ready
   */
  private getGatewayMerchant() {
    //  Reset located gateway merchant
    this.locatedGatewayMerchant = null;

    //  Loop and select gateway merchant
    if (this.locatedGatewayMerchantId) {
      for (const gatewayMerchant of this.gatewayMerchants) {
        if (
          this.locatedGatewayMerchantId === gatewayMerchant.gatewayMerchantId
        ) {
          this.locatedGatewayMerchant = gatewayMerchant;
        }
      }
    }

    //  Get payment gateway connections
    if (this.gatewayMerchant.gatewayType && this.locatedGatewayMerchantId) {
      new GatewayApi(AuthInterceptor.Instance)
        .getPaymentGatewaysOfGatewayMerchant({
          gatewayType:
            this.gatewayMerchant.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string
          gatewayMerchantId: this.locatedGatewayMerchantId as any as string,
        })
        .subscribe(
          (response) => {
            this.gatewayPaymentGateways = response;
            console.log(this.gatewayPaymentGateways);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Connect solution to selected gateway
   */
  private connectGatewayMerchant() {
    if (this.gatewayMerchant.gatewayType && this.locatedGatewayMerchantId) {
      if (
        confirm(
          "You are about to connect an existing gateway with this merchant. Are you sure you want to proceed?"
        )
      ) {
        new PaymentGatewayApi(AuthInterceptor.Instance)
          .createPaymentGateway({
            paymentGatewayRelation: {
              merchantId: this.merchant.id as number,
              solutionType: this.solution.type,
              gatewayType:
                this.gatewayMerchant.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string
              gatewayMerchantId: this.locatedGatewayMerchantId as any as string,
            },
          })
          .subscribe(
            () => {
              //  Toast
              eventHub.$emit("successToast", `Gateway connected`);

              //  Emit to merchant solution gateways
              this.$emit("updated-gateway");
            },
            (error) => {
              eventHub.$emit("httpError", error);
            }
          );
      }
    }
  }

  /**
   * Prepare registering new gateway
   */
  private registerGatewayMerchant() {
    this.gatewayMerchant.merchantName = this.merchant.name;
    this.gatewayMerchant.gatewayMerchantId = this.merchant.merchantId;
    this.registeringGatewayMerchant = true;
  }

  /**
   * Connect solution to newly registered gateway
   */
  private gatewayCreated(gatewayMerchant: GatewayMerchant) {
    if (gatewayMerchant.gatewayType) {
      new PaymentGatewayApi(AuthInterceptor.Instance)
        .createPaymentGateway({
          paymentGatewayRelation: {
            merchantId: this.merchant.id as number,
            solutionType: this.solution.type,
            gatewayType:
              gatewayMerchant.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string
            gatewayMerchantId: gatewayMerchant.gatewayMerchantId,
          },
        })
        .subscribe(
          () => {
            //  Toast
            eventHub.$emit("successToast", `Gateway created and connected`);

            //  Emit to merchant solution gateways
            this.$emit("updated-gateway");
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Convert to readable friendly text
   */
  private getGatewayType(gatewayType: GatewayType) {
    return gatewayTypeToUserText(gatewayType);
  }

  private closeModal() {
    //  Emit to merchant solution
    this.$emit("close-modal");
  }

  private closeSubModal() {
    this.registeringGatewayMerchant = false;
  }
}
