
import { AuthInterceptor } from "@/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PaymentGatewayApi } from "@fundraisingbureauet/admin-typescript-client";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";
import { PaymentGateway } from "@fundraisingbureauet/admin-typescript-client/models/PaymentGateway";
import { eventHub } from "@/router";

@Component({})
export default class SolutionGateways extends Vue {
  @Prop({ required: true })
  gatewayMerchant!: GatewayMerchant;

  @Prop({ required: true })
  solution!: Solution;

  paymentGateway: PaymentGateway | null = null;

  /**
   * Initialise
   */
  created() {
    this.getPaymentGateway();
  }

  /**
   * Get payment gateway
   */
  private getPaymentGateway() {
    if (this.gatewayMerchant.paymentGatewayGuid) {
      new PaymentGatewayApi(AuthInterceptor.Instance)
        .getPaymentGateway({
          paymentGatewayGuid: this.gatewayMerchant.paymentGatewayGuid,
          solutionType: this.solution.type,
        })
        .subscribe(
          (response) => {
            this.paymentGateway = response;
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Enable payment gateway
   */
  private enablePaymentGateway() {
    if (this.gatewayMerchant.paymentGatewayGuid) {
      if (
        confirm(
          "Are you sure you want to enable the gateway for this solution?"
        )
      ) {
        new PaymentGatewayApi(AuthInterceptor.Instance)
          .updatePaymentGateway({
            paymentGatewayGuid: this.gatewayMerchant.paymentGatewayGuid,
            paymentGatewayUpdate: {
              enabled: true,
              solutionType: this.solution.type,
            },
          })
          .subscribe(
            (response) => {
              this.paymentGateway = response;

              //  Toast
              eventHub.$emit("successToast", `Gateway enabled`);

              //  Emit to merchant solution gateways
              this.$emit("updated-gateway");
            },
            (error) => {
              eventHub.$emit("httpError", error);
            }
          );
      }
    }
  }

  /**
   * Disable payment gateway
   */
  private disablePaymentGateway() {
    if (this.gatewayMerchant.paymentGatewayGuid) {
      if (
        confirm(
          "Are you sure you want to disable the gateway for this solution?"
        )
      ) {
        new PaymentGatewayApi(AuthInterceptor.Instance)
          .updatePaymentGateway({
            paymentGatewayGuid: this.gatewayMerchant.paymentGatewayGuid,
            paymentGatewayUpdate: {
              enabled: false,
              solutionType: this.solution.type,
            },
          })
          .subscribe(
            (response) => {
              this.paymentGateway = response;

              //  Toast
              eventHub.$emit("successToast", `Gateway disabled`);

              //  Emit to merchant solution gateways
              this.$emit("updated-gateway");
            },
            (error) => {
              eventHub.$emit("httpError", error);
            }
          );
      }
    }
  }
}
