
import Modal from "@/components/shared/layout/Modal.vue";
import GatewayForm from "@/components/gateways/GatewayForm.vue";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ModalToolbarSettings } from "@/components/shared/layout/Modal.vue";

@Component({ components: { GatewayForm, Modal } })
export default class GatewayModal extends Vue {
  @Prop({ required: true })
  gatewayMerchant!: GatewayMerchant;

  @Prop({ required: false, default: false })
  insideMerchant!: boolean | null;

  toolbarSettings: ModalToolbarSettings | null = null;

  /**
   * Initialise
   */
  mounted() {
    this.toolbarSettings = {
      toolbarTitle: "Test",
      actions: [],
    };
  }

  private stopEditingMerchant() {
    //  Emit to merchant modal
    this.$emit("stop-editing-merchant");
  }

  private gatewayCreated(gatewayMerchant: GatewayMerchant) {
    //  Emit to gateway merchant list
    this.$emit("gateway-created", gatewayMerchant);
  }

  private gatewayUpdated(gatewayMerchant: GatewayMerchant) {
    //  Emit to gateway merchant list
    this.$emit("gateway-updated", gatewayMerchant);
  }

  private closeModal() {
    //  Emit to gateway merchant list
    this.$emit("close-modal");
  }
}
