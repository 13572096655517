"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticesApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class NoticesApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * List of notices
         */
        this.getNoticesList = () => {
            return this.request({
                path: '/notices',
                method: 'GET',
            });
        };
        /**
         * Upload notice JSON to S3 bucket
         */
        this.updateNoticesList = ({ notice }) => {
            runtime_1.throwIfNullOrUndefined(notice, 'updateNoticesList');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/notices',
                method: 'PUT',
                headers,
                body: notice,
            });
        };
    }
}
exports.NoticesApi = NoticesApi;
