"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceLogApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class InvoiceLogApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Mark the errored invoice log as corrected.
         */
        this.correctInvoiceLogLine = ({ logLineId }) => {
            runtime_1.throwIfNullOrUndefined(logLineId, 'correctInvoiceLogLine');
            return this.request({
                path: '/invoiceLog/{logLineId}/correct'.replace('{logLineId}', runtime_1.encodeURI(logLineId)),
                method: 'POST',
            });
        };
        /**
         * Retrieves the current invoicing status
         */
        this.getCurrentInvoicingStatus = () => {
            return this.request({
                path: '/invoiceLog/status',
                method: 'POST',
            });
        };
        /**
         * Retrieve complete invoice log with respect to the specified month and year.
         */
        this.getInvoiceLog = ({ month, year }) => {
            runtime_1.throwIfNullOrUndefined(month, 'getInvoiceLog');
            runtime_1.throwIfNullOrUndefined(year, 'getInvoiceLog');
            const query = {
                'month': month,
                'year': year,
            };
            return this.request({
                path: '/invoiceLog',
                method: 'GET',
                query,
            });
        };
    }
}
exports.InvoiceLogApi = InvoiceLogApi;
