"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class UsersApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Creates a new user
         */
        this.createUser = ({ createUser }) => {
            runtime_1.throwIfNullOrUndefined(createUser, 'createUser');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/users',
                method: 'POST',
                headers,
                body: createUser,
            });
        };
        /**
         * Deletes a user with the given id
         */
        this.deleteUser = ({ userId }) => {
            runtime_1.throwIfNullOrUndefined(userId, 'deleteUser');
            return this.request({
                path: '/users/{userId}'.replace('{userId}', runtime_1.encodeURI(userId)),
                method: 'DELETE',
            });
        };
        /**
         * Gets a specific user
         */
        this.getUserById = ({ userId }) => {
            runtime_1.throwIfNullOrUndefined(userId, 'getUserById');
            return this.request({
                path: '/users/{userId}'.replace('{userId}', runtime_1.encodeURI(userId)),
                method: 'GET',
            });
        };
        /**
         * Gets a list of users
         */
        this.getUserList = () => {
            return this.request({
                path: '/users',
                method: 'GET',
            });
        };
        /**
         * Login
         */
        this.login = ({ login }) => {
            runtime_1.throwIfNullOrUndefined(login, 'login');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/users/login',
                method: 'POST',
                headers,
                body: login,
            });
        };
        /**
         * Login
         */
        this.login_1 = ({ login }) => {
            runtime_1.throwIfNullOrUndefined(login, 'login_1');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/login',
                method: 'POST',
                headers,
                body: login,
            });
        };
        /**
         * Updates a user
         */
        this.updateUser = ({ userId, user }) => {
            runtime_1.throwIfNullOrUndefined(userId, 'updateUser');
            runtime_1.throwIfNullOrUndefined(user, 'updateUser');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/users/{userId}'.replace('{userId}', runtime_1.encodeURI(userId)),
                method: 'PUT',
                headers,
                body: user,
            });
        };
    }
}
exports.UsersApi = UsersApi;
