
import { Component, Vue } from "vue-property-decorator";
import {
  InvoiceLogApi,
  InvoiceLog,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import InvoiceLogLineList from "@/components/invoice/InvoiceLogLineList.vue";
import TextInputBlock from "@/components/shared/inputs/TextInputBlock.vue";
import Pagination from "@/components/shared/Pagination.vue";

@Component({
  components: { InvoiceLogLineList, TextInputBlock, Pagination },
})
export default class InvoiceLogComponent extends Vue {
  invoiceLog?: InvoiceLog | null = null;
  invoiceLogLoading = false;
  invoiceLogLoaded = false;
  invoiceLogErrored = false;
  searchCustomerString = "";
  isProcessingText = "";

  chosenYear = -1;
  chosenMonth = -1;
  chosenJobType = "ALL";

  pageSize = 10;
  infoPageSize = 20;
  logErrorsPage = 1;
  logWarningsPage = 1;
  logInfoPage = 1;

  years = {
    2018: 2018,
    2019: 2019,
    2020: 2020,
    2021: 2021,
    2022: 2022,
    2023: 2023,
    2024: 2024,
    2025: 2025,
    2026: 2026,
  };

  months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "Marts" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  jobTypes = [
    { id: "ALL", value: "All job types" },
    { id: "INVOICE_SCHEDULING", value: "INVOICE_SCHEDULING" },
    { id: "USAGE_COLLECTION", value: "USAGE_COLLECTION" },
    { id: "LINE_GENERATION", value: "LINE_GENERATION" },
    { id: "USAGE_LINE_GENERATION", value: "USAGE_LINE_GENERATION" },
    {
      id: "SUBSCRIPTION_LINE_GENERATION",
      value: "SUBSCRIPTION_LINE_GENERATION",
    },
    { id: "DRAFT_GENERATION", value: "DRAFT_GENERATION" },
    { id: "DRAFT_TRANSFERRAL", value: "DRAFT_TRANSFERRAL" },
    { id: "DRAFT_ENRICHMENT", value: "DRAFT_ENRICHMENT" },
    { id: "INVOICE_ACTION ", value: "INVOICE_ACTION " },
    { id: "PRICING ", value: "PRICING " },
    { id: "SMS_PAYOUT ", value: "SMS_PAYOUT " },
    { id: "REMAINING_USAGE ", value: "REMAINING_USAGE " },
    { id: "REMAINING_LINES ", value: "REMAINING_LINES " },
  ];

  get getInvoiceLog() {
    return this.invoiceLog;
  }
  set getInvoiceLog(value) {
    this.invoiceLog = value;
  }

  get processingText() {
    return this.isProcessingText;
  }
  set processingText(value) {
    this.isProcessingText = value;
  }

  get errorLogs() {
    const start = (this.logErrorsPage - 1) * this.pageSize;
    const end = start + this.pageSize;

    if (this.chosenJobType !== "ALL") {
      if (this.searchCustomerString) {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "ERROR" &&
              log.jobType?.toString() === this.chosenJobType &&
              (log.invoiceCustomerName
                ?.toLowerCase()
                .includes(this.searchCustomerString.toLowerCase()) ||
                log.invoiceCustomerId
                  ?.toString()
                  .includes(this.searchCustomerString.toLowerCase()))
          )
          .slice(start, end);
      } else {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "ERROR" &&
              log.jobType?.toString() === this.chosenJobType
          )
          .slice(start, end);
      }
    }

    if (this.searchCustomerString) {
      return this.getInvoiceLog?.logLines
        ?.filter(
          (log) =>
            log.type?.toString() === "ERROR" &&
            (log.invoiceCustomerName
              ?.toLowerCase()
              .includes(this.searchCustomerString.toLowerCase()) ||
              log.invoiceCustomerId
                ?.toString()
                .includes(this.searchCustomerString.toLowerCase()))
        )
        .slice(start, end);
    } else {
      return this.getInvoiceLog?.logLines
        ?.filter((log) => log.type?.toString() === "ERROR")
        .slice(start, end);
    }
  }

  get warningLogs() {
    const start = (this.logWarningsPage - 1) * this.pageSize;
    const end = start + this.pageSize;

    if (this.chosenJobType !== "ALL") {
      if (this.searchCustomerString) {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "WARNING" &&
              log.jobType?.toString() === this.chosenJobType &&
              (log.invoiceCustomerName
                ?.toLowerCase()
                .includes(this.searchCustomerString.toLowerCase()) ||
                log.invoiceCustomerId
                  ?.toString()
                  .includes(this.searchCustomerString.toLowerCase()))
          )
          .slice(start, end);
      } else {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "WARNING" &&
              log.jobType?.toString() === this.chosenJobType
          )
          .slice(start, end);
      }
    }

    if (this.searchCustomerString) {
      return this.getInvoiceLog?.logLines
        ?.filter(
          (log) =>
            log.type?.toString() === "WARNING" &&
            (log.invoiceCustomerName
              ?.toLowerCase()
              .includes(this.searchCustomerString.toLowerCase()) ||
              log.invoiceCustomerId
                ?.toString()
                .includes(this.searchCustomerString.toLowerCase()))
        )
        .slice(start, end);
    } else {
      return this.getInvoiceLog?.logLines
        ?.filter((log) => log.type?.toString() === "WARNING")
        .slice(start, end);
    }
  }

  get infoLogs() {
    const start = (this.logInfoPage - 1) * this.infoPageSize;
    const end = start + this.infoPageSize;

    if (this.chosenJobType !== "ALL") {
      if (this.searchCustomerString) {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "INFO" &&
              log.jobType?.toString() === this.chosenJobType &&
              (log.invoiceCustomerName
                ?.toLowerCase()
                .includes(this.searchCustomerString.toLowerCase()) ||
                log.invoiceCustomerId
                  ?.toString()
                  .includes(this.searchCustomerString.toLowerCase()))
          )
          .slice(start, end);
      } else {
        return this.getInvoiceLog?.logLines
          ?.filter(
            (log) =>
              log.type?.toString() === "INFO" &&
              log.jobType?.toString() === this.chosenJobType
          )
          .slice(start, end);
      }
    }

    if (this.searchCustomerString) {
      return this.getInvoiceLog?.logLines
        ?.filter(
          (log) =>
            log.type?.toString() === "INFO" &&
            (log.invoiceCustomerName
              ?.toLowerCase()
              .includes(this.searchCustomerString.toLowerCase()) ||
              log.invoiceCustomerId
                ?.toString()
                .includes(this.searchCustomerString.toLowerCase()))
        )
        .slice(start, end);
    } else {
      return this.getInvoiceLog?.logLines
        ?.filter((log) => log.type?.toString() === "INFO")
        .slice(start, end);
    }
  }

  get errorLogsCount() {
    if (this.errorLogs) {
      return this.getInvoiceLog?.logLines?.filter(
        (log) => log.type?.toString() === "ERROR"
      ).length;
    }

    return 0;
  }

  get warningLogsCount() {
    if (this.warningLogs) {
      return this.getInvoiceLog?.logLines?.filter(
        (log) => log.type?.toString() === "WARNING"
      ).length;
    }

    return 0;
  }

  get infoLogsCount() {
    if (this.infoLogs) {
      return this.getInvoiceLog?.logLines?.filter(
        (log) => log.type?.toString() === "INFO"
      ).length;
    }

    return 0;
  }

  get getChosenYear() {
    if (this.chosenYear === -1) {
      this.getChosenYear = new Date().getFullYear();
    }
    return this.chosenYear;
  }

  set getChosenYear(value) {
    this.chosenYear = value;
  }

  get getChosenMonth() {
    if (this.chosenMonth === -1) {
      this.getChosenMonth = new Date().getMonth() + 1;
    }
    return this.chosenMonth;
  }

  set getChosenMonth(value) {
    this.chosenMonth = value;
  }

  get getChosenJobType() {
    return this.chosenJobType;
  }

  set getChosenJobType(value) {
    this.chosenJobType = value;
  }

  get getInvoiceLogLoading() {
    return this.invoiceLogLoading;
  }

  set getInvoiceLogLoading(value) {
    this.invoiceLogLoading = value;
  }

  get getInvoiceLogLoaded() {
    return this.invoiceLogLoaded;
  }

  set getInvoiceLogLoaded(value) {
    this.invoiceLogLoaded = value;
  }

  get getInvoiceLogErrored() {
    return this.invoiceLogErrored;
  }

  set getInvoiceLogErrored(value) {
    this.invoiceLogErrored = value;
  }

  changeChosenYear(event: any) {
    this.getChosenYear = event.target.value;
    this.loadInvoiceLog(false);
  }

  changeChosenMonth(event: any) {
    this.getChosenMonth = event.target.value;
    this.loadInvoiceLog(false);
  }

  changeChosenJobType(event: any) {
    this.logErrorsPage = 1;
    this.logWarningsPage = 1;
    this.logInfoPage = 1;
    this.getChosenJobType = event.target.value;
  }

  /**
   * Initialise
   */
  created() {
    this.loadInvoiceLog(true);
    this.setProcessingText(1);
  }

  private setProcessingText(count: number) {
    let text = "processing";
    /* eslint-disable-next-line */
    const _this = this;

    if (count == 1) {
      text += ".";
      count += 1;
    } else if (count === 2) {
      text += "..";
      count += 1;
    } else {
      text += "...";
      count = 1;
    }

    this.processingText = text;

    setTimeout(function () {
      _this.setProcessingText(count);
    }, 700);
  }

  private loadInvoiceLog(setLoading: boolean) {
    if (setLoading) {
      this.invoiceLogLoading = true;
    }

    new InvoiceLogApi(AuthInterceptor.Instance)
      .getInvoiceLog({ month: this.getChosenMonth, year: this.getChosenYear })
      .subscribe(
        (response) => {
          if (setLoading) {
            this.getInvoiceLogLoading = false;
            this.getInvoiceLogLoaded = true;
            this.getInvoiceLogErrored = false;
          }

          this.getInvoiceLog = response;

          if (this.getInvoiceLog.isProcessing) {
            /* eslint-disable-next-line */
            const _this = this;
            setTimeout(function () {
              _this.loadInvoiceLog(false);
            }, 5000);
          }
        },
        (error) => {
          eventHub.$emit("httpError", error);
          this.getInvoiceLogLoading = false;
          this.getInvoiceLogErrored = true;
        }
      );
  }

  setErrorsPage(pageNumber: number) {
    this.logErrorsPage = pageNumber;
  }

  setWarningsPage(pageNumber: number) {
    this.logWarningsPage = pageNumber;
  }

  setInfoPage(pageNumber: number) {
    this.logInfoPage = pageNumber;
  }

  correctInvoiceLog(logLineId: number) {
    let username = "me";

    if (AuthInterceptor.Instance.username) {
      username = AuthInterceptor.Instance.username;
    }

    if (this.getInvoiceLog?.logLines) {
      for (let i = 0; i < this.getInvoiceLog.logLines.length; i++) {
        if (this.getInvoiceLog.logLines[i].logLineId === logLineId) {
          this.getInvoiceLog.logLines[i].correctedByUser = username;
        }
      }
    }
  }
}
