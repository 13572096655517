
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import Pagination from "@/components/shared/Pagination.vue";

export interface ListColumn<T> {
  key: string;
  field?: keyof T;
  header?: string;
  render?: (item: T) => string;
  class?: string;
  columnClass?: string;
  dateAgoText?: boolean;
}

@Component({ components: { Pagination } })
export default class ElementList<T> extends Vue {
  @Prop({ required: false, default: null }) title!: string | null;
  @Prop({ required: true }) items!: any[];
  @Prop({ required: true }) columns!: ListColumn<T>[];
  @Prop({ required: true }) itemKey!: string;
  @Prop({ required: false, default: true }) showPagination!: boolean;
  @Prop({ required: false, default: true }) showHeaders!: boolean;
  @Prop({ required: false, default: false }) selectable!: boolean;
  @Prop({ required: false, default: false }) bgColor!: string;
  @Prop({ required: false, default: false }) colorItemKey!: string;

  selected: { [key: string]: boolean } = {};
  currentItems: any[] = [];

  @Prop({ required: false, default: "" }) search!: string;
  @Prop({ required: false, default: undefined }) state!: boolean;
  @Prop({ required: false, default: null }) filterFunction!:
    | ((item: any, filter: string) => boolean)
    | null;

  pageNumber = 1;
  pageSize = 15;

  listErrored = false;
  listLoading = false;

  get elementItems() {
    return [...this.items];
  }

  get filteredItems() {
    let beforeFilter = this.elementItems;

    //  Default filter
    const filterFunction:
      | ((item: any, filter: string, state: boolean) => boolean)
      | null = this.filterFunction;

    //  Component filter
    if (filterFunction) {
      beforeFilter = beforeFilter.filter((item) =>
        filterFunction(item, this.search, this.state)
      );
    }

    return beforeFilter;
  }

  @Watch("items")
  onItemsUpdated(items: any, oldItems: any) {
    //  this.setPage(this.pageNumber);
    this.currentItems = [...this.elementItems].slice(0, this.pageSize);
    this.setPage(this.pageNumber);
  }

  @Watch("state")
  @Watch("search")
  private filterElement(search: string) {
    this.setPage(1);
  }

  @Emit()
  onItemClicked(item: any) {
    //  Noop
  }

  //  Initialise
  created() {
    if (this.$route.query.page) {
      this.pageNumber = parseInt(this.$route.query.page as string);
    }
    this.setPage(this.pageNumber);
  }

  //  Functions
  selectionChanged(event: any, key: string, checked: boolean) {
    if (checked) {
      this.selected[key] = true;
    } else {
      delete this.selected[key];
    }
  }

  selectedElements(): T[] {
    return this.elementItems.filter(
      (value) => this.selected[value[this.itemKey]]
    );
  }

  setPage(pageNumber: number) {
    this.pageNumber = pageNumber;
    const start = (pageNumber - 1) * this.pageSize;
    const end = start + this.pageSize;

    this.currentItems = this.filteredItems.slice(start, end);

    //  Emit
    this.$emit("pageChanged", pageNumber);
  }

  getRowColor(item:any, colorItemKey?: string) {
    if(colorItemKey) {
      const searchValue = !colorItemKey.startsWith("!");
      colorItemKey = colorItemKey.replace("!", "");
      if(item[colorItemKey] !== undefined && item[colorItemKey] === searchValue) {
          return {'background-color': 'rgba(200,0,0,0.25)'};
        }
    }
  }
}
