
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  InvoiceLinesApi,
  InvoiceLineApi,
  InvoiceDraft,
  InvoiceLine,
  CreateInvoiceLine,
  FindInvoiceLinesStateEnum,
  InvoiceDraftApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import ComponentInvoiceLine from "@/components/invoice/InvoiceLine.vue";
import InvoiceLineList from "@/components/invoice/InvoiceLineList.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

@Component({
  components: {
    InvoiceLineList,
    ComponentInvoiceLine,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class InvoiceDraftLine extends Vue {
  @Prop({ required: false }) invoiceCustomerId!: number;
  @Prop({ required: false, default: false }) showDate!: boolean;
  @Prop({ required: true }) invoiceDraft!: InvoiceDraft;
  @Prop({ required: false, default: true }) showHeader!: boolean;
  @Prop({ required: false, default: "" }) customerName!: string;

  invoiceLineToPush!: InvoiceLine;
  createInvoiceLine!: CreateInvoiceLine;
  showCreateNew = false;
  showBody = false;

  excludedInvoiceLines: InvoiceLine[] = [];
  excludedInvoiceLinesLoading = false;
  excludedInvoiceLinesErrored = false;

  get ShowBody() {
    return this.showBody;
  }

  set ShowBody(value) {
    this.showBody = value;
  }

  get ShowCreateNew() {
    return this.showCreateNew;
  }

  set ShowCreateNew(value) {
    this.showCreateNew = value;
  }

  get InvoiceTypeText() {
    if (this.invoiceDraft.economicLayoutId === 19) {
      return "Faktura";
    } else {
      return "Kreditnota";
    }
  }

  newInvoice = {
    description: "",
    economicsLayoutId: null,
    economicsProductNumber: null,
    invoiceCustomerId: null,
    invoiceDraftId: null,
    month: null,
    unitPrice: null,
    quantity: null,
    year: null,
  };

  chosenEconomicsProduct = 1;

  get getChosenEconomicsProduct() {
    return this.chosenEconomicsProduct;
  }

  set getChosenEconomicsProduct(value) {
    this.chosenEconomicsProduct = value;
  }

  get getExcludedInvoiceLines() {
    return this.excludedInvoiceLines.filter(
      (invoice) => invoice.invoiceCustomerId === this.invoiceCustomerId
    );
  }

  set getExcludedInvoiceLines(value) {
    this.excludedInvoiceLines = value;
  }

  changeChosenEconomicsProduct(event: any) {
    this.chosenEconomicsProduct = event.target.value;
  }

  economicsProducts = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "Marts" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  private ShowBodyAndLoadExcluded() {
    this.ShowBody = !this.ShowBody;

    if (
      !this.getExcludedInvoiceLines ||
      this.getExcludedInvoiceLines.length == 0
    ) {
      this.loadExcludedInvoiceLines();
    }
  }

  private formatCurrency(num: number) {
    const formatter = new Intl.NumberFormat("da", {
      style: "currency",
      currency: "DKK",
    });

    return formatter.format(num);
  }

  private loadExcludedInvoiceLines() {
    this.excludedInvoiceLinesLoading = true;
    new InvoiceLinesApi(AuthInterceptor.Instance)
      .findInvoiceLines({
        state: "EXCLUDED" as FindInvoiceLinesStateEnum, //FindInvoiceLinesStateEnum.NEWINDRAFTSENTEXCLUDEDDELETED
        invoiceCustomerId: this.invoiceCustomerId,
      })
      .subscribe(
        (response) => {
          this.excludedInvoiceLinesLoading = false;
          this.getExcludedInvoiceLines = response;
        },
        (error) => {
          eventHub.$emit("httpError", error);
        },
        () => {
          this.excludedInvoiceLinesErrored = true;
        }
      );
  }

  private downloadInvoicePdf() {
    this.excludedInvoiceLinesLoading = true;
    new InvoiceDraftApi(AuthInterceptor.Instance)
      .getInvoiceDraftPdf({
        invoiceDraftId: this.invoiceDraft.invoiceDraftId!,
      })
      .subscribe(
        (response) => {
          this.downloadBlobAsFile(response);
          console.log(response);
        },
        (error) => {
          eventHub.$emit("httpError", error);
        },
        () => {
          this.excludedInvoiceLinesErrored = true;
        }
      );
  }

  private downloadBlobAsFile(data: Blob) {
    const contentType = "application/pdf";
    if (!data) {
      console.error(" No data");
      return;
    }

    const date = new Date(this.invoiceDraft.sentToExternalTs!);

    const blob = new Blob([data], { type: contentType }),
      e = document.createEvent("MouseEvents"),
      a = document.createElement("a");

    a.download = `invoice ${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")} ${this.customerName}.pdf`;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
    e.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  }

  private formatCreatedTs(createdTs: Date) {
    const theDate = new Date(createdTs);

    return theDate.getFullYear() + "-" + (theDate.getMonth() + 1);
  }

  get getStyle() {
    return "padding-left:15px;padding-right:5px;";
  }

  get InputStyle() {
    return "padding:7px;";
  }

  @Emit()
  createNewInvoiceLine(validate: () => Promise<any>) {
    validate().then(() => {
      const theDate = new Date(this.invoiceDraft.createdTs!);
      const theMonth = theDate.getMonth() + 1;

      this.createInvoiceLine = {
        invoiceCustomerId: this.invoiceDraft.invoiceCustomerId!,
        invoiceDraftId: this.invoiceDraft.invoiceDraftId!,
        year: theDate.getFullYear(),
        month: theMonth,
        description: this.newInvoice.description,
        unitPrice: this.newInvoice.unitPrice!,
        economicsProductNumber: this.newInvoice.economicsProductNumber!,
        quantity: this.newInvoice.quantity!,
      };

      new InvoiceLineApi(AuthInterceptor.Instance)
        .createInvoiceLine({ createInvoiceLine: this.createInvoiceLine })
        .subscribe(
          (response) => {
            let username = "me";

            if (AuthInterceptor.Instance.username) {
              username = AuthInterceptor.Instance.username;
            }

            this.invoiceLineToPush = {
              invoiceLineId: response,
              invoiceCustomerId: this.invoiceDraft.invoiceCustomerId!,
              invoiceDraftId: this.invoiceDraft.invoiceCustomerId!,
              year: theDate.getFullYear(),
              month: theMonth,
              description: this.newInvoice.description,
              unitNetPrice: this.newInvoice.unitPrice!,
              price: this.newInvoice.quantity! * this.newInvoice.unitPrice!,
              economicsProductNumber: this.newInvoice.economicsProductNumber!,
              quantity: this.newInvoice.quantity!,
              createdBy: username,
            };

            this.$emit(
              "createNewInvoiceLine",
              this.invoiceDraft,
              this.invoiceLineToPush
            );
            this.ShowCreateNew = false;
            this.newInvoice = {
              description: "",
              economicsLayoutId: null,
              economicsProductNumber: null,
              invoiceCustomerId: null,
              invoiceDraftId: null,
              month: null,
              unitPrice: null,
              quantity: null,
              year: null,
            };
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    });
  }

  @Emit()
  excludeInvoiceLine(invoiceDraft: InvoiceDraft, invoiceLine: InvoiceLine) {
    if (this.invoiceDraft.invoiceDraftId === invoiceDraft.invoiceDraftId) {
      if (this.invoiceDraft.invoiceLines) {
        for (let l = 0; l < this.invoiceDraft.invoiceLines.length; l++) {
          const line = this.invoiceDraft.invoiceLines[l];
          if (line.invoiceLineId === invoiceLine.invoiceLineId) {
            if (!this.excludedInvoiceLines) {
              this.excludedInvoiceLines = [];
            }

            if (!this.invoiceDraft.amountExpectedInvoiced) {
              this.invoiceDraft.amountExpectedInvoiced = 0;
            }

            this.invoiceDraft.amountExpectedInvoiced = parseInt(
              (this.invoiceDraft.amountExpectedInvoiced! - line.price!).toFixed(
                2
              )
            );
            this.excludedInvoiceLines.push(this.invoiceDraft.invoiceLines[l]);
            this.invoiceDraft!.invoiceLines!.splice(l, 1);
          }
        }
      }
    }
  }

  @Emit()
  includeInvoiceLine(invoiceDraft: InvoiceDraft, invoiceLine: InvoiceLine) {
    if (this.invoiceDraft.invoiceDraftId === invoiceDraft.invoiceDraftId) {
      if (this.excludedInvoiceLines) {
        for (let l = 0; l < this.excludedInvoiceLines.length; l++) {
          const line = this.excludedInvoiceLines[l];
          if (line.invoiceLineId === invoiceLine.invoiceLineId) {
            if (!this.invoiceDraft.invoiceLines) {
              this.invoiceDraft.invoiceLines = [];
            }

            if (!this.invoiceDraft.amountExpectedInvoiced) {
              this.invoiceDraft.amountExpectedInvoiced = 0;
            }

            this.invoiceDraft.amountExpectedInvoiced = parseInt(
              (this.invoiceDraft.amountExpectedInvoiced! + line.price!).toFixed(
                2
              )
            );
            this.invoiceDraft.invoiceLines!.push(line);
            this.excludedInvoiceLines.splice(l, 1);
          }
        }
      }
    }
  }

  @Emit()
  deleteInvoiceLine(
    invoiceDraft: InvoiceDraft,
    invoiceLine: InvoiceLine,
    isExcluded: boolean
  ) {
    this.$emit("deleteInvoiceLine", invoiceDraft, invoiceLine, isExcluded);
  }
}
