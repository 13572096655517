"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindUsageQuantityEnum = exports.UsageApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class UsageApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieve usage, either everything or that which has yet to be invoiced (required). Can be filtered by invoice customer and/or product (optional).
         */
        this.findUsage = ({ quantity, invoiceCustomerId, productKey }) => {
            runtime_1.throwIfNullOrUndefined(quantity, 'findUsage');
            const query = {
                'quantity': quantity,
            };
            if (invoiceCustomerId != null) {
                query['invoiceCustomerId'] = invoiceCustomerId;
            }
            if (productKey != null) {
                query['productKey'] = productKey;
            }
            return this.request({
                path: '/usage',
                method: 'GET',
                query,
            });
        };
        /**
         * Retrieve a specific UsageRecord.
         */
        this.getUsageRecord = ({ usageRecordId }) => {
            runtime_1.throwIfNullOrUndefined(usageRecordId, 'getUsageRecord');
            return this.request({
                path: '/usage/{usageRecordId}'.replace('{usageRecordId}', runtime_1.encodeURI(usageRecordId)),
                method: 'GET',
            });
        };
        /**
         * Register usage. Must be of a known usage type.
         */
        this.registerUsage = ({ usage }) => {
            runtime_1.throwIfNullOrUndefined(usage, 'registerUsage');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/usage',
                method: 'POST',
                headers,
                body: usage,
            });
        };
    }
}
exports.UsageApi = UsageApi;
/**
 * @export
 * @enum {string}
 */
var FindUsageQuantityEnum;
(function (FindUsageQuantityEnum) {
    FindUsageQuantityEnum["ALL"] = "ALL";
    FindUsageQuantityEnum["UNINVOICED"] = "UNINVOICED";
})(FindUsageQuantityEnum = exports.FindUsageQuantityEnum || (exports.FindUsageQuantityEnum = {}));
