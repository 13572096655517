var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"modalDiv",attrs:{"tabindex":"0"},on:{"keyup":function($event){$event.stopPropagation();return _vm.closeOnEscape.apply(null, arguments)}}},[_c('div',{class:{
      'of-sub-sub-modal': _vm.subSubModal,
      'of-sub-modal': !_vm.subSubModal && _vm.subModal,
      'of-modal': !_vm.subSubModal && !_vm.subModal,
    }},[_c('div',{staticClass:"modal-header",style:([_vm.toolbarSettings?.bgColor ? {'background': '' + _vm.toolbarSettings.bgColor + ''} : {'background': ''}])},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"v-divider"}),_c('div',{staticClass:"left-aligned"},[(_vm.toolbar)?_c('div',[_c('ModalToolbar',{attrs:{"toolbar-title":(_vm.toolbarSettings && _vm.toolbarSettings.toolbarTitle) || '',"actions":(_vm.toolbarSettings && _vm.toolbarSettings.actions) || []},on:{"action-clicked":_vm.toolbarActionClicked}})],1):_vm._e()]),_c('div',{staticClass:"right-aligned"},[_c('button',{staticClass:"of-modal-close",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_c('i',{staticClass:"dashboard-icon icon-close"})])])]),_c('div',{staticClass:"modal-content"},[_c('div',[_vm._t("default")],2)])]),_c('div',{class:{
      'of-sub-sub-modal-bg': _vm.subSubModal,
      'of-sub-modal-bg': !_vm.subSubModal && _vm.subModal,
      'of-modal-bg': !_vm.subSubModal && !_vm.subModal,
    },on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }