
import gatewayTypeToUserText from "@/components/gateways/GatewayUtil";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { GatewaysApi } from "@fundraisingbureauet/admin-typescript-client";
import { MobilePayConsentStatusApi } from "@fundraisingbureauet/admin-typescript-client";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { GatewayType } from "@fundraisingbureauet/admin-typescript-client/models/GatewayType";
import { eventHub } from "@/router";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  length,
  digits,
  // max_value,
  // min_value,
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("length", length);
extend("digits", digits);
// extend("max", max_value);
// extend("min", min_value);

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class GatewayForm extends Vue {
  @Prop({ required: false, default: null })
  gatewayMerchant!: GatewayMerchant;

  gatewayType: string | undefined = "";
  gatewayMerchantId: string | undefined = "";
  merchantName: string | undefined = "";
  config: { [key: string]: object } | undefined = {};

  showExtra = false;
  consentState = "Checking...";

  get gatewayTypeConfig() {
    if (this.gatewayType) {
      //  Loop gateway types and return relevant config fields
      for (const configurableGateway of this.$root.$data.configurableGateways) {
        if (configurableGateway.type === this.gatewayType) {
          //  Reset config
          this.config = {};

          //  Loop gateway type config fields and transfer default value
          for (const field of configurableGateway.fields) {
            //  Detect value
            if (this.gatewayMerchant.config) {
              this.config[field.fieldName] =
                this.gatewayMerchant.config[field.fieldName];
            }

            //  Detect defaults
            else if (field.type === "BOOLEAN") {
              this.config[field.fieldName] = field.defaultBoolean;
            } else if (field.type === "INTEGER") {
              this.config[field.fieldName] = field.defaultNumber;
            } else {
              this.config[field.fieldName] = field.defaultString;
            }
          }

          return configurableGateway.fields;
        }
      }
    }
    return [];
  }

  /**
   * Initialise
   */
  created() {
    if (this.gatewayMerchant) {
      this.gatewayType = this.gatewayMerchant.gatewayType || "";
      this.gatewayMerchantId = this.gatewayMerchant.gatewayMerchantId || "";
      this.merchantName = this.gatewayMerchant.merchantName || "";
      this.config = this.gatewayMerchant.config || {};
      this.checkMobilePayConsentStatus();
    }
  }

  /**
   * Create Gateway Merchant
   */
  private createGatewayMerchant() {
    //  Verify
    if (
      this.gatewayType &&
      //HACK for now: if it is direct debit, we allow submission even if merchant Id is not filled, if it is not DD we expect it to be filled.
      (this.gatewayType === GatewayType.DIRECTDEBIT ||
        (this.gatewayType !== GatewayType.DIRECTDEBIT &&
          this.gatewayMerchantId)) &&
      this.gatewayMerchantId &&
      this.merchantName &&
      this.config
    ) {
      //  Execute
      new GatewaysApi(AuthInterceptor.Instance)
        .createGatewayMerchant({
          gatewayType: this.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string,
          gatewayMerchant: {
            gatewayType: this.gatewayType as GatewayType,
            gatewayMerchantId: this.gatewayMerchantId,
            merchantName: this.merchantName,
            config: this.config ? this.config : undefined,
          },
        })
        .subscribe(
          (gatewayMerchant) => {
            //  Toast
            eventHub.$emit(
              "successToast",
              `Gateway merchant: ${this.gatewayMerchantId} created`
            );

            //  Emit to gateway merchant modal
            this.$emit("gateway-created", gatewayMerchant);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    } else {
      eventHub.$emit(
        "warningToast",
        "Auch, something is missing. Please review"
      );
    }
  }

  /**
   * Update Gateway Merchant
   */
  private updateGatewayMerchant() {
    //  Verify
    if (
      this.gatewayType &&
      this.gatewayMerchantId &&
      this.merchantName &&
      this.config
    ) {
      //  Execute
      new GatewaysApi(AuthInterceptor.Instance)
        .updateGatewayMerchant({
          gatewayType: this.gatewayType.toUpperCase() as GatewayType, // convert eNum to uppercase string,
          gatewayMerchantId: this.gatewayMerchantId,
          gatewayMerchant: {
            gatewayType: this.gatewayType as GatewayType,
            gatewayMerchantId: this.gatewayMerchantId,
            merchantName: this.merchantName,
            config: this.config ? this.config : undefined,
          },
        })
        .subscribe(
          (gatewayMerchant) => {
            //  Reassign properties
            gatewayMerchant.createdTs = this.gatewayMerchant.createdTs;
            gatewayMerchant.authTokens = this.gatewayMerchant.authTokens;
            gatewayMerchant.extra = this.gatewayMerchant.extra;

            //  Toast
            eventHub.$emit(
              "successToast",
              `Gateway merchant: ${this.gatewayMerchantId} updated`
            );

            //  Emit to gateway merchant modal
            this.$emit("gateway-updated", gatewayMerchant);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Check MobilePay consent status
   */
  private checkMobilePayConsentStatus() {
    if (
      this.gatewayType === GatewayType.MOBILEPAYSUBSCRIPTIONS &&
      this.gatewayMerchant.authTokens &&
      this.gatewayMerchant.extra &&
      this.gatewayMerchant.extra.consentId
    ) {
      //  Execute
      new MobilePayConsentStatusApi(AuthInterceptor.Instance)
        .getMobilePayConsentStatus({
          consentId: this.gatewayMerchant.extra.consentId as any as string,
        })
        .subscribe(
          (response) => {
            if (response.isConsentValid) {
              this.consentState = "Given";
            } else {
              this.consentState = "Not given";
            }
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  /**
   * Check if gatewayType is MobilePay Subscriptions
   */
  private isMobilePaySubscriptions(gatewayType: GatewayType) {
    if (
      this.gatewayType === GatewayType.MOBILEPAYSUBSCRIPTIONS &&
      this.gatewayMerchant.authTokens &&
      this.gatewayMerchant.extra &&
      this.gatewayMerchant.extra.consentToken
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Toggle additional information
   */
  private toggleAdditional() {
    if (this.showExtra) {
      this.showExtra = false;
    } else {
      this.showExtra = true;
    }
  }

  private isDirectDebit(gatewayType: GatewayType) {
    return this.gatewayType === GatewayType.DIRECTDEBIT;
  }

  /**
   * Convert to readable friendly text
   */
  private getGatewayType(gatewayType: GatewayType) {
    return gatewayTypeToUserText(gatewayType);
  }
}
