import Vue from "vue";

const Dates = Vue.filter("formatDate", (timestamp: string) => {
  //  2020-01-01
  if (timestamp) {
    return (
      '<span class="Ts" title="' +
      timestamp.replace("T", " ").replace("Z", "") +
      '">' +
      timestamp.substring(0, 10) +
      "</span>"
    );
  } else {
    return "";
  }
});
export default Dates;
