"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewaysApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class GatewaysApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Create a merchant configured in the specified gateway
         */
        this.createGatewayMerchant = ({ gatewayType, gatewayMerchant }) => {
            runtime_1.throwIfNullOrUndefined(gatewayType, 'createGatewayMerchant');
            runtime_1.throwIfNullOrUndefined(gatewayMerchant, 'createGatewayMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/gateway/{gatewayType}/merchant'.replace('{gatewayType}', runtime_1.encodeURI(gatewayType)),
                method: 'POST',
                headers,
                body: gatewayMerchant,
            });
        };
        /**
         * Retrieves a list of available gateways
         */
        this.getAvailableGateways = () => {
            return this.request({
                path: '/gateways',
                method: 'GET',
            });
        };
        /**
         * Gets a map of all gateway configurations
         */
        this.getGatewayConfigurations = () => {
            return this.request({
                path: '/gateways/configurations',
                method: 'GET',
            });
        };
        /**
         * Retrieve a specific merchant configured in the specified gateway
         */
        this.getGatewayMerchant = ({ gatewayType, gatewayMerchantId }) => {
            runtime_1.throwIfNullOrUndefined(gatewayType, 'getGatewayMerchant');
            runtime_1.throwIfNullOrUndefined(gatewayMerchantId, 'getGatewayMerchant');
            return this.request({
                path: '/gateway/{gatewayType}/merchant/{gatewayMerchantId}'.replace('{gatewayType}', runtime_1.encodeURI(gatewayType)).replace('{gatewayMerchantId}', runtime_1.encodeURI(gatewayMerchantId)),
                method: 'GET',
            });
        };
        /**
         * Retrieve a list of all merchants configured in gateway
         */
        this.getGatewayMerchants = ({ gatewayType }) => {
            runtime_1.throwIfNullOrUndefined(gatewayType, 'getGatewayMerchants');
            return this.request({
                path: '/gateway/{gatewayType}/merchants'.replace('{gatewayType}', runtime_1.encodeURI(gatewayType)),
                method: 'GET',
            });
        };
        /**
         * Update a specific merchant configured in the specified gateway
         */
        this.updateGatewayMerchant = ({ gatewayType, gatewayMerchantId, gatewayMerchant }) => {
            runtime_1.throwIfNullOrUndefined(gatewayType, 'updateGatewayMerchant');
            runtime_1.throwIfNullOrUndefined(gatewayMerchantId, 'updateGatewayMerchant');
            runtime_1.throwIfNullOrUndefined(gatewayMerchant, 'updateGatewayMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/gateway/{gatewayType}/merchant/{gatewayMerchantId}'.replace('{gatewayType}', runtime_1.encodeURI(gatewayType)).replace('{gatewayMerchantId}', runtime_1.encodeURI(gatewayMerchantId)),
                method: 'PUT',
                headers,
                body: gatewayMerchant,
            });
        };
    }
}
exports.GatewaysApi = GatewaysApi;
