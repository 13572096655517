
import { Component, Prop, Vue } from "vue-property-decorator";

export class InfoElement {
  header: string;
  value?: string | number;
  link?: string;
  img?: string;
  action?: {};

  constructor(header: string, value: string | number) {
    this.header = header;
    this.value = value;
  }
}

@Component({})
export default class InfoBox extends Vue {
  @Prop({})
  header?: string;

  @Prop({})
  items?: InfoElement[];
}
