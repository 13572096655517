"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantsApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class MerchantsApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Imports all merchants
         */
        this.importAll = () => {
            return this.request({
                path: '/merchants/importall',
                method: 'POST',
            });
        };
        /**
         * Retrieve list of all merchants
         */
        this.listMerchants = () => {
            return this.request({
                path: '/merchants',
                method: 'GET',
            });
        };
        /**
         * Retrieve all merchantIds
         */
        this.retrieveAllMerchantIds = () => {
            return this.request({
                path: '/merchants/merchantIds',
                method: 'GET',
            });
        };
        /**
         * Retrieve all merchantIds for solution
         */
        this.retrieveAllMerchantIdsForSolutionType = ({ solutionType }) => {
            runtime_1.throwIfNullOrUndefined(solutionType, 'retrieveAllMerchantIdsForSolutionType');
            const query = {
                'solutionType': solutionType,
            };
            return this.request({
                path: '/merchants/solutionMerchantIds',
                method: 'GET',
                query,
            });
        };
    }
}
exports.MerchantsApi = MerchantsApi;
