"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsageType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var UsageType;
(function (UsageType) {
    UsageType["EPAYMOBILEPAYONLINETRANSACTIONS"] = "EPAY_MOBILEPAY_ONLINE_TRANSACTIONS";
    UsageType["EPAYTRANSACTIONS"] = "EPAY_TRANSACTIONS";
    UsageType["QUICKPAYMOBILEPAYONLINETRANSACTIONS"] = "QUICKPAY_MOBILEPAY_ONLINE_TRANSACTIONS";
    UsageType["QUICKPAYEXTERNALACQUIRERTRANSACTIONS"] = "QUICKPAY_EXTERNAL_ACQUIRER_TRANSACTIONS";
    UsageType["QUICKPAYTRANSACTIONS"] = "QUICKPAY_TRANSACTIONS";
    UsageType["KEYWORDS"] = "KEYWORDS";
    UsageType["ONBOARDINGAGREEMENTCHANGE"] = "ONBOARDING_AGREEMENT_CHANGE";
    UsageType["ONBOARDINGCHANGEPAYMENTMETHOD"] = "ONBOARDING_CHANGE_PAYMENT_METHOD";
    UsageType["ONBOARDINGSIGNUP"] = "ONBOARDING_SIGNUP";
    UsageType["SMS4TCOST"] = "SMS_4T_COST";
    UsageType["SMSPREMIUMREPLY"] = "SMS_PREMIUM_REPLY";
    UsageType["SMSSENT"] = "SMS_SENT";
    UsageType["SUPPORT"] = "SUPPORT";
})(UsageType = exports.UsageType || (exports.UsageType = {}));
