"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindInvoiceLinesStateEnum = exports.InvoiceLinesApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class InvoiceLinesApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieve all invoice lines in the specified InvoiceLineState (required). Can be filtered by invoice customer and/or product (optional).
         */
        this.findInvoiceLines = ({ state, invoiceCustomerId, productKey }) => {
            runtime_1.throwIfNullOrUndefined(state, 'findInvoiceLines');
            const query = {
                'state': state,
            };
            if (invoiceCustomerId != null) {
                query['invoiceCustomerId'] = invoiceCustomerId;
            }
            if (productKey != null) {
                query['productKey'] = productKey;
            }
            return this.request({
                path: '/invoiceLines',
                method: 'GET',
                query,
            });
        };
    }
}
exports.InvoiceLinesApi = InvoiceLinesApi;
/**
 * @export
 * @enum {string}
 */
var FindInvoiceLinesStateEnum;
(function (FindInvoiceLinesStateEnum) {
    FindInvoiceLinesStateEnum["NEWINDRAFTSENTEXCLUDEDDELETED"] = "NEW | IN_DRAFT | SENT | EXCLUDED | DELETED";
})(FindInvoiceLinesStateEnum = exports.FindInvoiceLinesStateEnum || (exports.FindInvoiceLinesStateEnum = {}));
