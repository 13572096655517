
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  LogLine,
  InvoiceLogApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({
  components: {},
})
export default class ComponentLogLine extends Vue {
  @Prop({ required: true }) logLine!: LogLine;
  @Prop({ required: false, default: false }) isErrors?: boolean;

  showBody = false;

  get ShowBody() {
    return this.showBody;
  }

  set ShowBody(value) {
    this.showBody = value;
  }

  get loggedDateTime() {
    const date = new Date(this.logLine.loggedTs!);
    return date.toLocaleString("da-DK");
  }

  get dataException() {
    if (this.logLine?.data?.exception) {
      return this.logLine.data.exception;
    }

    return this.logLine.data;
  }

  correctInvoiceLog() {
    if (confirm("Are you sure you want to set this error as corrected?")) {
      let logLineId = 0;

      if (this.logLine.logLineId) {
        logLineId = this.logLine.logLineId;
      }

      new InvoiceLogApi(AuthInterceptor.Instance)
        .correctInvoiceLogLine({ logLineId })
        .subscribe(
          () => {
            this.$emit("correctInvoiceLog", logLineId);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }
}
