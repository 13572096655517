
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import {
  InvoiceDraft,
  InvoiceLine,
} from "@fundraisingbureauet/admin-typescript-client";
import ComponentInvoiceLine from "@/components/invoice/InvoiceLine.vue";

@Component({
  components: { ComponentInvoiceLine },
})
export default class InvoiceLineList extends Vue {
  @Prop({ required: true }) invoiceDraft!: InvoiceDraft;
  @Prop({ required: true }) invoiceLines!: InvoiceLine[];
  @Prop({ required: false, default: false }) isExcluded!: boolean;

  @Emit()
  excludeInvoiceLine(invoiceDraft: InvoiceDraft, invoiceLine: InvoiceLine) {
    this.$emit("excludeInvoiceLine", invoiceDraft, invoiceLine);
  }

  @Emit()
  includeInvoiceLine(invoiceDraft: InvoiceDraft, invoiceLine: InvoiceLine) {
    this.$emit("includeInvoiceLine", invoiceDraft, invoiceLine);
  }

  @Emit()
  deleteInvoiceLine(
    invoiceDraft: InvoiceDraft,
    invoiceLine: InvoiceLine,
    isExcluded: boolean
  ) {
    this.$emit("deleteInvoiceLine", invoiceDraft, invoiceLine, isExcluded);
  }

  get getStyle() {
    return "padding-left:5px;padding-right:5px;";
  }
}
