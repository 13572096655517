"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class MerchantApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Creates a new merchant
         */
        this.createMerchant = ({ merchant }) => {
            runtime_1.throwIfNullOrUndefined(merchant, 'createMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/merchant',
                method: 'POST',
                headers,
                body: merchant,
            });
        };
        /**
         * Gets a specific merchant
         */
        this.getMerchant = ({ id }) => {
            runtime_1.throwIfNullOrUndefined(id, 'getMerchant');
            return this.request({
                path: '/merchant/{id}'.replace('{id}', runtime_1.encodeURI(id)),
                method: 'GET',
            });
        };
        /**
         * Get system information a specific merchant for a specified SolutionType
         */
        this.getMerchantInfoByMerchantId = ({ merchantId, solutionType }) => {
            runtime_1.throwIfNullOrUndefined(merchantId, 'getMerchantInfoByMerchantId');
            runtime_1.throwIfNullOrUndefined(solutionType, 'getMerchantInfoByMerchantId');
            const query = {
                'solutionType': solutionType,
            };
            return this.request({
                path: '/merchant/{merchantId}/info'.replace('{merchantId}', runtime_1.encodeURI(merchantId)),
                method: 'GET',
                query,
            });
        };
        /**
         * Request cancellation
         */
        this.requestMerchantCancellation = ({ id }) => {
            runtime_1.throwIfNullOrUndefined(id, 'requestMerchantCancellation');
            return this.request({
                path: '/merchant/{id}/requestCancellation'.replace('{id}', runtime_1.encodeURI(id)),
                method: 'PUT',
            });
        };
        /**
         * Updates a specific merchant
         */
        this.updateMerchant = ({ id, merchant }) => {
            runtime_1.throwIfNullOrUndefined(id, 'updateMerchant');
            runtime_1.throwIfNullOrUndefined(merchant, 'updateMerchant');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/merchant/{id}'.replace('{id}', runtime_1.encodeURI(id)),
                method: 'PUT',
                headers,
                body: merchant,
            });
        };
    }
}
exports.MerchantApi = MerchantApi;
