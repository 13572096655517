"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogJobType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var LogJobType;
(function (LogJobType) {
    LogJobType["INVOICESCHEDULING"] = "INVOICE_SCHEDULING";
    LogJobType["USAGECOLLECTION"] = "USAGE_COLLECTION";
    LogJobType["LINEGENERATION"] = "LINE_GENERATION";
    LogJobType["USAGELINEGENERATION"] = "USAGE_LINE_GENERATION";
    LogJobType["SUBSCRIPTIONLINEGENERATION"] = "SUBSCRIPTION_LINE_GENERATION";
    LogJobType["DRAFTGENERATION"] = "DRAFT_GENERATION";
    LogJobType["DRAFTTRANSFERRAL"] = "DRAFT_TRANSFERRAL";
    LogJobType["DRAFTENRICHMENT"] = "DRAFT_ENRICHMENT";
    LogJobType["INVOICEACTION"] = "INVOICE_ACTION";
    LogJobType["PRICING"] = "PRICING";
    LogJobType["SMSPAYOUT"] = "SMS_PAYOUT";
    LogJobType["REMAININGUSAGE"] = "REMAINING_USAGE";
    LogJobType["REMAININGLINES"] = "REMAINING_LINES";
})(LogJobType = exports.LogJobType || (exports.LogJobType = {}));
