"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemsApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class SystemsApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Gets a list of configurable systems
         */
        this.getConfigurableSystems = () => {
            return this.request({
                path: '/systems',
                method: 'GET',
            });
        };
        /**
         * Retrieves all available systems and their configuration requirements
         */
        this.listAvailableSystems = () => {
            return this.request({
                path: '/systems/available',
                method: 'GET',
            });
        };
    }
}
exports.SystemsApi = SystemsApi;
