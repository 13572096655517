"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobilePayConsentStatusApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class MobilePayConsentStatusApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Gets the status of mobile pay consent
         */
        this.getMobilePayConsentStatus = ({ consentId }) => {
            const query = {};
            if (consentId != null) {
                query['consentId'] = consentId;
            }
            return this.request({
                path: '/mobilePayConsentStatus',
                method: 'GET',
                query,
            });
        };
    }
}
exports.MobilePayConsentStatusApi = MobilePayConsentStatusApi;
