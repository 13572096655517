"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class CustomerApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Activate the specific InvoiceCustomer.
         */
        this.activateCustomer = ({ invoiceCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceCustomerId, 'activateCustomer');
            return this.request({
                path: '/customer/{invoiceCustomerId}/activate'.replace('{invoiceCustomerId}', runtime_1.encodeURI(invoiceCustomerId)),
                method: 'POST',
            });
        };
        /**
         * Create new customer
         */
        this.createCustomer = ({ upsertCustomer }) => {
            runtime_1.throwIfNullOrUndefined(upsertCustomer, 'createCustomer');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/customer',
                method: 'POST',
                headers,
                body: upsertCustomer,
            });
        };
        /**
         * Deactivate the specific InvoiceCustomer.
         */
        this.deactivateCustomer = ({ invoiceCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceCustomerId, 'deactivateCustomer');
            return this.request({
                path: '/customer/{invoiceCustomerId}/deactivate'.replace('{invoiceCustomerId}', runtime_1.encodeURI(invoiceCustomerId)),
                method: 'POST',
            });
        };
        /**
         * Retrieve a specific InvoiceCustomer.
         */
        this.getCustomer = ({ invoiceCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceCustomerId, 'getCustomer');
            return this.request({
                path: '/customer/{invoiceCustomerId}'.replace('{invoiceCustomerId}', runtime_1.encodeURI(invoiceCustomerId)),
                method: 'GET',
            });
        };
        /**
         * Retrieve the name of a customer in e-conomic via the specified e-conomic customer id.
         */
        this.getEconomicCustomer = ({ economicCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(economicCustomerId, 'getEconomicCustomer');
            const query = {
                'economicCustomerId': economicCustomerId,
            };
            return this.request({
                path: '/customer/economic',
                method: 'GET',
                query,
            });
        };
        /**
         * Retrieve the name of a customer in Podio via the specified Podio customer id.
         */
        this.getPodioCustomer = ({ podioCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(podioCustomerId, 'getPodioCustomer');
            const query = {
                'podioCustomerId': podioCustomerId,
            };
            return this.request({
                path: '/customer/podio',
                method: 'GET',
                query,
            });
        };
        /**
         * Retrieve the name of a customer in Zdensk via the specified zendesk customer id.
         */
        this.getZendeskCustomer = ({ zendeskCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(zendeskCustomerId, 'getZendeskCustomer');
            const query = {
                'zendeskCustomerId': zendeskCustomerId,
            };
            return this.request({
                path: '/customer/zendesk',
                method: 'GET',
                query,
            });
        };
        /**
         * Update existing customer
         */
        this.updateCustomer = ({ invoiceCustomerId, upsertCustomer }) => {
            runtime_1.throwIfNullOrUndefined(invoiceCustomerId, 'updateCustomer');
            runtime_1.throwIfNullOrUndefined(upsertCustomer, 'updateCustomer');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/customer/{invoiceCustomerId}'.replace('{invoiceCustomerId}', runtime_1.encodeURI(invoiceCustomerId)),
                method: 'PUT',
                headers,
                body: upsertCustomer,
            });
        };
    }
}
exports.CustomerApi = CustomerApi;
