"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class ProductsApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieve a list of all registered Podio subscription products.
         */
        this.getPodioSubscriptionProducts = () => {
            return this.request({
                path: '/products/podioSubscriptions',
                method: 'GET',
            });
        };
        /**
         * Retrieve a list of all products.
         */
        this.getProducts = () => {
            return this.request({
                path: '/products',
                method: 'GET',
            });
        };
    }
}
exports.ProductsApi = ProductsApi;
