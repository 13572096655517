
import Pagination from "@/components/shared/Pagination.vue";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";

@Component({
  components: {
    Pagination,
    ElementList,
  },
})
export default class Merchants extends Vue {
  @Prop({ required: true }) merchants?: Merchant[];
  @Prop({ required: false }) search?: string;
  @Prop({ required: false }) showPagination!: boolean;
  @Prop({ required: false, default: null }) title!: string | null;
  newMerchant?: object | null = null;
  currentMerchant?: Merchant | null = null;
  columns: ListColumn<Merchant>[] = [
    {
      header: "Merchant ID",
      key: "merchantId",
      field: "merchantId",
    },
    {
      header: "Name",
      key: "name",
      field: "name",
      columnClass: "exact-width-300px"
    },
    {
      header: "Customer",
      key: "customer.name",
      //render: (Merchant) => `${Merchant.customer?.name? Merchant.customer.name: "⚠️"}`,
      render: (item) => (item.customer?.countryCode ? this.getFlag(item.customer?.countryCode.toLowerCase())  + ' ' : '⚠️') + (item.customer ? item.customer.name : ''),
      class: "exact-width-300px"
    },
    // {
    //   header: "Country",
    //   key: "customer.country",
    //   render: (Merchant) => `${Merchant.customer?.countryCode? Merchant.customer.countryCode: "⚠️"}`,
    // },
    {
      header: "Sandbox",
      key: "invoiceSandboxSubscription",
      render: (Merchant) => `${Merchant.invoiceSandboxSubscription? "✅": ""}`,
      class: "text-center color-red inline-block",
      columnClass: "text-center",
    },
    {
      header: "SMS Account",
      key: "smsPaymentAccountNumber",
      field: "smsPaymentAccountNumber"
    },
    {
      header: "Created",
      key: "createdTs",
      field: "createdTs",
      dateAgoText: true
    }

  ];
  merchantsPage = 1;
  merchantListLoading = false;
  merchantListErrored = false;

  @Emit()
  editMerchant(merchant: Merchant) {
    window.console.log("editMerchant: " + merchant.merchantId + " on emit");

    //  Emit to parent
    this.$emit("edit-merchant", merchant);
  }

  private getFlag(value?: string) {
    if(value !== undefined) {
      switch (value) {
        case "dk":
        case "se":
        case "no":
        case "is":
        case "fi":
          return '<img class="sm-flag" src="/img/icons/flags/' + (value.toLowerCase()) + '.png" alt="flag"/>'
        default:
          return "";
      }
    }
    return "";
  }

  private filterMerchant(item: Merchant, filter: string) {
    if (filter === "") {
      return item;
    } else {
      return (
          item.merchantId.toLowerCase().includes(filter.toLowerCase()) ||
          item.name.toLowerCase().includes(filter.toLowerCase()) ||
          item?.customer?.name?.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }
}
