"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class PayoutApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Generates the sms payout informatio file for the specified year and month.
         * Generates the payout file
         */
        this.generatePayoutFile = ({ month, year }) => {
            runtime_1.throwIfNullOrUndefined(month, 'generatePayoutFile');
            runtime_1.throwIfNullOrUndefined(year, 'generatePayoutFile');
            const query = {
                'month': month,
                'year': year,
            };
            return this.request({
                path: '/payout/generateFile',
                method: 'GET',
                query,
                responseType: 'blob'
            });
        };
    }
}
exports.PayoutApi = PayoutApi;
