
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import {
  InvoiceLineApi,
  InvoiceDraft,
  InvoiceLine,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({
  components: {},
})
export default class UiInvoiceLine extends Vue {
  @Prop({ required: true }) invoiceDraft!: InvoiceDraft;
  @Prop({ required: true }) invoiceLine!: InvoiceLine;
  @Prop({ required: false, default: false }) isExcluded!: boolean;

  data?: Record<string, any> | undefined = {};

  showBody = false;

  get ShowBody() {
    return this.showBody;
  }

  set ShowBody(value) {
    this.showBody = value;
  }

  get productNameFormat() {
    return this.invoiceLine.product?.toLowerCase();
  }

  get ProductName() {
    if (this.invoiceLine.product) {
      return this.invoiceLine.product.replaceAll("-", " ");
    }
    return "";
  }

  get GetData() {
    return this.data;
  }

  set GetData(value) {
    this.data = value;
  }

  private formatCurrency(num: number) {
    const formatter = new Intl.NumberFormat("da", {
      style: "currency",
      currency: "DKK",
    });

    return formatter.format(num);
  }

  private ShowBodyAndLoadData() {
    this.ShowBody = !this.ShowBody;
    let invoiceLineId = 0;

    if (this.invoiceLine.invoiceLineId) {
      invoiceLineId = this.invoiceLine.invoiceLineId;
    }

    new InvoiceLineApi(AuthInterceptor.Instance)
      .getInvoiceLine({ invoiceLineId })
      .subscribe(
        (response) => {
          this.GetData = response.data;
          console.log(response);
          console.log(response.data);
          console.log(this.GetData);
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  @Emit()
  excludeInvoiceLine() {
    let invoiceLineId = 0;

    if (this.invoiceLine.invoiceLineId) {
      invoiceLineId = this.invoiceLine.invoiceLineId;
    }

    new InvoiceLineApi(AuthInterceptor.Instance)
      .excludeInvoiceLineFromDraft({ invoiceLineId })
      .subscribe(
        () => {
          this.$emit("excludeInvoiceLine", this.invoiceDraft, this.invoiceLine);
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  @Emit()
  includeInvoiceLine() {
    new InvoiceLineApi(AuthInterceptor.Instance)
      .includeInvoiceLineInDraft({
        invoiceLineId: this.invoiceLine.invoiceLineId!,
        invoiceDraftId: { invoiceDraftId: this.invoiceDraft.invoiceDraftId! },
      })
      .subscribe(
        () => {
          this.$emit("includeInvoiceLine", this.invoiceDraft, this.invoiceLine);
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  @Emit()
  deleteInvoiceLine() {
    new InvoiceLineApi(AuthInterceptor.Instance)
      .deleteManualInvoiceLine({
        invoiceLineId: this.invoiceLine.invoiceLineId!,
      })
      .subscribe(
        () => {
          this.$emit(
            "deleteInvoiceLine",
            this.invoiceDraft,
            this.invoiceLine,
            this.isExcluded
          );
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }
}
