"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomersApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class CustomersApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieve customer information from Podio, Economic and Zendesk, and all related OF configurations.
         */
        this.getCustomerInfoAcrossServices = () => {
            return this.request({
                path: '/customer/checkAll',
                method: 'GET',
            });
        };
        /**
         * Retrieve all invoice customers. Note that related Merchants are not retrieved with this call, fetch single customer for related merchants.
         */
        this.getCustomers = () => {
            return this.request({
                path: '/customers',
                method: 'GET',
            });
        };
    }
}
exports.CustomersApi = CustomersApi;
