"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolutionApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class SolutionApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Updates a solution
         */
        this.updateSolution = ({ id, solutionUpdate }) => {
            runtime_1.throwIfNullOrUndefined(id, 'updateSolution');
            runtime_1.throwIfNullOrUndefined(solutionUpdate, 'updateSolution');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/solution/{id}'.replace('{id}', runtime_1.encodeURI(id)),
                method: 'PUT',
                headers,
                body: solutionUpdate,
            });
        };
    }
}
exports.SolutionApi = SolutionApi;
