"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var SystemType;
(function (SystemType) {
    SystemType["COMMUNICATION"] = "COMMUNICATION";
    SystemType["DATASET"] = "DATASET";
    SystemType["PAYMENTENGINE"] = "PAYMENT_ENGINE";
    SystemType["PAYMENTSESSIONHANDLER"] = "PAYMENT_SESSION_HANDLER";
    SystemType["ONBOARDING"] = "ONBOARDING";
    SystemType["SMSINBOUND"] = "SMS_INBOUND";
    SystemType["SMSOUTBOUND"] = "SMS_OUTBOUND";
    SystemType["EXPORTER"] = "EXPORTER";
    SystemType["SHORTLINKER"] = "SHORTLINKER";
    SystemType["EMAILSETUPVALIDATOR"] = "EMAIL_SETUP_VALIDATOR";
    SystemType["BSGATEWAYLANDINGPAGE"] = "BS_GATEWAY_LANDING_PAGE";
    SystemType["SMSCPH"] = "SMSCPH";
    SystemType["EVENT"] = "EVENT";
    SystemType["PAYMENTRECEIVER"] = "PAYMENT_RECEIVER";
})(SystemType = exports.SystemType || (exports.SystemType = {}));
