"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobsApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class JobsApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Trigger the job that attempts to enrich all InvoiceDrafts that are in a \"SENT\" state.
         */
        this.enrichSentDrafts = () => {
            return this.request({
                path: '/jobs/draftEnrichment',
                method: 'POST',
            });
        };
        /**
         * Trigger the job that sends all uninvoiced InvoiceDrafts to economics. Job is executed asynchronously.
         */
        this.sendDraftsToEconomics = () => {
            return this.request({
                path: '/jobs/sendToEconomics',
                method: 'POST',
            });
        };
        /**
         * Trigger the job that creates the invoice drafts based on the generated invoice lines. Job is executed asynchronously.
         */
        this.triggerInvoiceDraftGeneration = () => {
            return this.request({
                path: '/jobs/generateDrafts',
                method: 'POST',
            });
        };
        /**
         * Trigger the job that generate invoice lines based on the collected usage and found subscriptions. Job is executed asynchronously.
         */
        this.triggerLineGeneration = ({ lineGenerationJobConf }) => {
            runtime_1.throwIfNullOrUndefined(lineGenerationJobConf, 'triggerLineGeneration');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/jobs/generateLines',
                method: 'POST',
                headers,
                body: lineGenerationJobConf,
            });
        };
        /**
         * Trigger the job that collects all usage data. Specify month and year, and optionally a subset of usage collectors, for collection. Job is executed asynchronously.
         */
        this.triggerUsageCollection = ({ usageCollectionJobConf }) => {
            runtime_1.throwIfNullOrUndefined(usageCollectionJobConf, 'triggerUsageCollection');
            const headers = {
                'Content-Type': 'application/json',
            };
            return this.request({
                path: '/jobs/usageCollection',
                method: 'POST',
                headers,
                body: usageCollectionJobConf,
            });
        };
    }
}
exports.JobsApi = JobsApi;
