"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var LogType;
(function (LogType) {
    LogType["INFO"] = "INFO";
    LogType["WARNING"] = "WARNING";
    LogType["ERROR"] = "ERROR";
})(LogType = exports.LogType || (exports.LogType = {}));
