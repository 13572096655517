
import Modal, { ModalToolbarAction, ModalToolbarSettings } from "@/components/shared/layout/Modal.vue";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import { Customer, CustomerApi, InvoiceLine } from "@fundraisingbureauet/admin-typescript-client";
import InfoBox, { InfoElement } from "@/components/shared/layout/InfoBox.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MerchantList from "@/components/merchants/MerchantList.vue";
import InvoiceDraftList from "@/components/invoice/InvoiceDraftList.vue";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import CustomerDialog from "@/components/customers/CustomerDialog.vue";
import MerchantModal from "@/components/merchants/MerchantModal.vue";
import MerchantListSimpel from "@/components/merchants/MerchantListSimpel.vue";
import MerchantHistory from "@/components/merchants/MerchantHistory.vue";

@Component({
  components: {
    MerchantHistory,
    MerchantListSimpel,
    Modal,
    InfoBox,
    MerchantList,
    InvoiceDraftList,
    CustomerDialog,
    MerchantModal,
  },
})
export default class CustomerModal extends Vue {
  @Prop({ required: true }) customer!: Customer;

  customerInfoElements: InfoElement[] = [];
  toolbarSettings: ModalToolbarSettings = {
    toolbarTitle: "",
    actions: [],
  };

  merchants: Array<Merchant> = [];
  merchantsListLoading = false;
  merchantsListErrored = false;

  invoiceLines: Array<InvoiceLine> = [];
  invoiceLinesListLoading = false;
  invoiceLinesListErrored = false;

  customerToEdit: Customer | null = null;
  currentMerchant: Merchant | null = null;
  isActive = this.customer.active;

  get getIsActive() {
    return this.isActive;
  }

  set getIsActive(value) {
    this.isActive = value;
  }

  /**
   * Initialise
   */
  created() {
    this.loadMerchants();
    this.extractCustomerInfo();
  }

  private extractCustomerInfo() {
    if (this.customer) {
      this.toolbarSettings.toolbarTitle = this.customer.name + "";
      this.toolbarSettings.actions = this.createToolBarActions();
      this.customerInfoElements = [
        { header: 'Active',  value: this.renderActive(this.customer?.active) },
        { header: "Id", value: this.customer.invoiceCustomerId },
        {
          header: "Business Code",
          value: this.renderLinkText(this.customer.businessCode,'https://datacvr.virk.dk/enhed/virksomhed/', true)

        },
        { header: "Phone No.", value: this.customer.msisdn },
        { header: "E-mail", value:  this.renderLinkText(this.customer.email, 'https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=', true) },
        { header: "Address", value: this.customer.address + `<div>` + this.customer.postCode + ` `   + this.customer.city },
        { header: "Country", value: this.customer.countryCode, img:  this.getFlag(this.customer?.countryCode) },
        { header: "Website", value: this.renderLinkText(this.customer?.website, undefined, true) },
        { header: "Podio", value: this.renderLinkText(this.customer?.podioId?.toString(), "https://podio.com/flowtwocom/fund/apps/kunder/items/", true) },
        { header: "Zendesk", value: this.renderLinkText(this.customer?.zendeskId?.toString(), "https://onlinefundraising.zendesk.com/agent/organizations/", true) },
        { header: "E-conomic Id", value: this.customer?.economicId },

        { header: "GDPR Info", value: this.customer.gdprInformation }
      ];
    }
  }

  private getFlag(value?: string) {
    if(value !== undefined) {
      switch (value) {
        case "DK":
        case "SE":
        case "NO":
        case "IS":
        case "FI":
          return '<img class="sm-flag" src="/img/icons/flags/' + (value.toLowerCase()) + '.png" alt="flag"/>'
        default:
          return "";
      }
    }
    return "";
  }

  private renderActive(active?: boolean) {
    return this.isActive ? '<span style="font-size: 20px;">☒</span>' : '<span style="font-size: 20px;">☐</span>';
  }

  private renderLinkText(value?: string, base?: string, showValue?: boolean) {
    if(value) {
      if(base) {
        return "<a target='_blank' href='"+base+value+"'>"+(showValue? value: 'Link')+"</a>";
      }

      return "<a target='_blank' href='"+value+"'>"+(showValue? value: 'Link') +"</a>";
    }
  }

  private createToolBarActions(): ModalToolbarAction[] {
    const toolbarActions: ModalToolbarAction[] = [
      {
        name: "Edit",
      },
    ];

    return toolbarActions;
  }

  closeCustomerModal() {
    this.customerToEdit = null;
  }

  editCustomer() {
    this.customerToEdit = this.customer;
  }

  @Emit()
  added(customer: Customer) {
    this.$emit("added", customer);
  }

  @Emit()
  customerUpdated(customer: Customer) {
    this.customerToEdit = null;
    this.$emit("customerUpdated", customer);
  }

  private loadMerchants() {
    this.merchantsListLoading = true;

    new CustomerApi(AuthInterceptor.Instance)
      .getCustomer({ invoiceCustomerId: this.customer.invoiceCustomerId! })
      .subscribe(
        (response) => {
          this.merchantsListLoading = false;

          if (response.merchants) {
            this.merchants = response.merchants;
          }
        },
        (error) => {
          eventHub.$emit("httpError", error);
          this.merchantsListErrored = false;
        },
        () => {
          this.merchantsListLoading = false;
        }
      );
  }

  private changeActiveStatus(event: any) {
    if (
      this.getIsActive &&
      confirm("WARNING: Are you sure you want to inactivate this customer?")
    ) {
      new CustomerApi(AuthInterceptor.Instance)
        .deactivateCustomer({
          invoiceCustomerId: this.customer.invoiceCustomerId!,
        })
        .subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    } else if (
      !this.getIsActive &&
      confirm("WARNING: Are you sure you want to activate this customer?")
    ) {
      new CustomerApi(AuthInterceptor.Instance)
        .activateCustomer({
          invoiceCustomerId: this.customer.invoiceCustomerId!,
        })
        .subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    } else {
      event.preventDefault();
      return false;
    }
  }

  private toolbarActionClicked(action: string) {
    switch (action) {
      case "Edit":
        this.editCustomer();
        break;
    }
  }

  private closeModal() {
    //  Emit to merchant list
    console.log("CustomerModal.vue: closeModal");
    this.$emit("close-modal");
  }

  private closeMerchantModal() {
    this.currentMerchant = null;
  }

  private editMerchant(merchant: Merchant) {
    window.console.log(
      "editMerchant: " + merchant.merchantId + " via customer modal"
    );

    this.currentMerchant = merchant;
    this.viewEntity(this.currentMerchant.merchantId);
  }

  private viewEntity(guid: string | undefined) {
    //  Skip double navigation
    if (this.$route.query.merchantId && this.$route.query.merchantId == guid) {
      return false;
    }

    const query: { [key: string]: any } = {};
    query["merchantId"] = guid;

    this.$router.push({
      name: "Merchants",
      query: query,
    });
  }
}
