"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GatewayType;
(function (GatewayType) {
    GatewayType["EPAY"] = "EPAY";
    GatewayType["DIBS"] = "DIBS";
    GatewayType["QUICKPAY"] = "QUICKPAY";
    GatewayType["SALTPAY"] = "SALTPAY";
    GatewayType["MOBILEPAYSUBSCRIPTIONS"] = "MOBILEPAY_SUBSCRIPTIONS";
    GatewayType["BETALINGSSERVICE"] = "BETALINGSSERVICE";
    GatewayType["SMSINBOUND"] = "SMS_INBOUND";
    GatewayType["SMSINBOUNDSANDBOX"] = "SMS_INBOUND_SANDBOX";
    GatewayType["DIRECTDEBIT"] = "DIRECT_DEBIT";
    GatewayType["TEST"] = "TEST";
})(GatewayType = exports.GatewayType || (exports.GatewayType = {}));
