import {
  Configuration,
  Middleware,
  RequestArgs,
  ResponseArgs,
} from "@fundraisingbureauet/admin-typescript-client/runtime";

export class AuthInterceptor extends Configuration {
  private static config: AuthInterceptor;

  private constructor() {
    const middleware: Middleware[] = [
      {
        pre(request: RequestArgs): RequestArgs {
          const headers: { [key: string]: any } = {
            ...request.headers,
          };
          headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

          return {
            ...request,
            headers: headers,
          };
        },

        post(response: ResponseArgs): ResponseArgs {
          return response;
        },
      },
    ];

    super({ basePath: process.env.VUE_APP_API_LOCATION, middleware });
  }

  public static get Instance() : Configuration {
    return AuthInterceptor.config || (AuthInterceptor.config = new this());
  }
}
