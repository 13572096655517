"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwIfNullOrUndefined = exports.throwIfRequired = exports.COLLECTION_FORMATS = exports.RequiredError = exports.BaseAPI = exports.querystring = exports.Configuration = exports.encodeURI = exports.BASE_PATH = void 0;
const rxjs_1 = require("rxjs");
const ajax_1 = require("rxjs/ajax");
const operators_1 = require("rxjs/operators");
exports.BASE_PATH = ''.replace(/\/+$/, '');
exports.encodeURI = (value) => encodeURIComponent(String(value));
class Configuration {
    constructor(configuration = {}) {
        this.configuration = configuration;
    }
    get basePath() {
        return this.configuration.basePath || exports.BASE_PATH;
    }
    get middleware() {
        return this.configuration.middleware || [];
    }
    get username() {
        return this.configuration.username;
    }
    get password() {
        return this.configuration.password;
    }
    get apiKey() {
        const apiKey = this.configuration.apiKey;
        if (!apiKey) {
            return undefined;
        }
        return typeof apiKey === 'string' ? () => apiKey : apiKey;
    }
    get accessToken() {
        const accessToken = this.configuration.accessToken;
        if (!accessToken) {
            return undefined;
        }
        return typeof accessToken === 'string' ? () => accessToken : accessToken;
    }
}
exports.Configuration = Configuration;
const queryString = (params) => Object.keys(params)
    .map((key) => {
    const value = params[key];
    return (value instanceof Array)
        ? value.map((val) => `${exports.encodeURI(key)}=${exports.encodeURI(val)}`).join('&')
        : `${exports.encodeURI(key)}=${exports.encodeURI(value)}`;
})
    .join('&');
// alias fallback for not being a breaking change
exports.querystring = queryString;
/**
 * This is the base class for all generated API classes.
 */
class BaseAPI {
    constructor(configuration = new Configuration()) {
        this.configuration = configuration;
        this.middleware = [];
        this.withMiddleware = (middlewares) => {
            const next = this.clone();
            next.middleware = next.middleware.concat(middlewares);
            return next;
        };
        this.withPreMiddleware = (preMiddlewares) => this.withMiddleware(preMiddlewares.map((pre) => ({ pre })));
        this.withPostMiddleware = (postMiddlewares) => this.withMiddleware(postMiddlewares.map((post) => ({ post })));
        this.request = (requestOpts) => this.rxjsRequest(this.createRequestArgs(requestOpts)).pipe(operators_1.map((res) => {
            if (res.status >= 200 && res.status < 300) {
                return res.response;
            }
            throw res;
        }));
        this.createRequestArgs = (requestOpts) => {
            let url = this.configuration.basePath + requestOpts.path;
            if (requestOpts.query !== undefined && Object.keys(requestOpts.query).length !== 0) {
                // only add the queryString to the URL if there are query parameters.
                // this is done to avoid urls ending with a '?' character which buggy webservers
                // do not handle correctly sometimes.
                url += '?' + queryString(requestOpts.query);
            }
            return {
                url,
                method: requestOpts.method,
                headers: requestOpts.headers,
                body: requestOpts.body instanceof FormData ? requestOpts.body : JSON.stringify(requestOpts.body),
                responseType: requestOpts.responseType || 'json',
            };
        };
        this.rxjsRequest = (params) => rxjs_1.of(params).pipe(operators_1.map((request) => {
            this.middleware.filter((item) => item.pre).forEach((mw) => (request = mw.pre(request)));
            return request;
        }), operators_1.concatMap((args) => ajax_1.ajax(args).pipe(operators_1.map((response) => {
            this.middleware.filter((item) => item.post).forEach((mw) => (response = mw.post(response)));
            return response;
        }))));
        /**
         * Create a shallow clone of `this` by constructing a new instance
         * and then shallow cloning data members.
         */
        this.clone = () => Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        this.middleware = configuration.middleware;
    }
}
exports.BaseAPI = BaseAPI;
/**
 * @deprecated
 * export for not being a breaking change
 */
class RequiredError extends Error {
    constructor() {
        super(...arguments);
        this.name = 'RequiredError';
    }
}
exports.RequiredError = RequiredError;
exports.COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};
/**
 * @deprecated
 */
exports.throwIfRequired = (params, key, nickname) => {
    if (!params || params[key] == null) {
        throw new RequiredError(`Required parameter ${key} was null or undefined when calling ${nickname}.`);
    }
};
exports.throwIfNullOrUndefined = (value, nickname) => {
    if (value == null) {
        throw new Error(`Parameter "${value}" was null or undefined when calling "${nickname}".`);
    }
};
