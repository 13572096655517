"use strict";
// tslint:disable
/**
 * Admin Api
 * Admin and Invoice application for OnlineFundraising / Fundraisingbureauet.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetInvoiceDraftsStateEnum = exports.InvoiceDraftApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class InvoiceDraftApi extends runtime_1.BaseAPI {
    constructor() {
        super(...arguments);
        /**
         * Retrieve a specific InvoiceDraft.
         */
        this.getInvoiceDraft = ({ invoiceDraftId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceDraftId, 'getInvoiceDraft');
            return this.request({
                path: '/invoiceDraft/{invoiceDraftId}'.replace('{invoiceDraftId}', runtime_1.encodeURI(invoiceDraftId)),
                method: 'GET',
            });
        };
        /**
         * Retrieve the actual sent invoice as pdf.
         */
        this.getInvoiceDraftPdf = ({ invoiceDraftId }) => {
            runtime_1.throwIfNullOrUndefined(invoiceDraftId, 'getInvoiceDraftPdf');
            return this.request({
                path: '/invoiceDraft/{invoiceDraftId}/pdf'.replace('{invoiceDraftId}', runtime_1.encodeURI(invoiceDraftId)),
                method: 'GET',
                responseType: 'blob'
            });
        };
        /**
         * Retrieve all drafts in the specified InvoiceDraftState. Can be filtered by invoice customer (optional).
         */
        this.getInvoiceDrafts = ({ state, invoiceCustomerId }) => {
            runtime_1.throwIfNullOrUndefined(state, 'getInvoiceDrafts');
            const query = {
                'state': state,
            };
            if (invoiceCustomerId != null) {
                query['invoiceCustomerId'] = invoiceCustomerId;
            }
            return this.request({
                path: '/invoiceDrafts',
                method: 'GET',
                query,
            });
        };
        /**
         * Retrieve all drafts for the specified month and year combination (required).
         */
        this.getInvoiceDraftsInInvoicePeriod = ({ month, year }) => {
            runtime_1.throwIfNullOrUndefined(month, 'getInvoiceDraftsInInvoicePeriod');
            runtime_1.throwIfNullOrUndefined(year, 'getInvoiceDraftsInInvoicePeriod');
            const query = {
                'month': month,
                'year': year,
            };
            return this.request({
                path: '/invoiceDrafts/inPeriod',
                method: 'GET',
                query,
            });
        };
    }
}
exports.InvoiceDraftApi = InvoiceDraftApi;
/**
 * @export
 * @enum {string}
 */
var GetInvoiceDraftsStateEnum;
(function (GetInvoiceDraftsStateEnum) {
    GetInvoiceDraftsStateEnum["ALLNEWSENTINVOICEDDELETED"] = "ALL | NEW | SENT | INVOICED | DELETED";
})(GetInvoiceDraftsStateEnum = exports.GetInvoiceDraftsStateEnum || (exports.GetInvoiceDraftsStateEnum = {}));
