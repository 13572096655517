
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

export interface PageLink {
  page: number;
  active: boolean;
}

@Component({})
export default class Pagination extends Vue {
  @Prop({ required: true }) pageNumber!: number;
  @Prop({ required: true }) pageSize!: number;
  @Prop({ required: true }) totalElements!: number;

  get hasNext(): boolean {
    const pageCount = Math.ceil(this.totalElements / this.pageSize);
    return this.pageNumber < pageCount;
  }

  get hasPrev(): boolean {
    return this.pageNumber !== 1;
  }

  get links(): PageLink[] {
    const links: PageLink[] = [];

    if (this.pageNumber !== 1) {
      links.push({
        page: this.pageNumber - 1,
        active: false,
      });
    }

    links.push({
      page: this.pageNumber,
      active: true,
    });

    const pageCount = Math.ceil(this.totalElements / this.pageSize);
    if (pageCount > this.pageNumber) {
      links.push({
        page: this.pageNumber + 1,
        active: false,
      });
    }
    return links;
  }

  nextPage() {
    this.setPage(this.pageNumber + 1);
  }

  prevPage() {
    this.setPage(this.pageNumber - 1);
  }

  @Emit()
  setPage(pageNumber: number) {
    console.log(`Settings page number: ${pageNumber}`);
  }
}
