
import Modal from "@/components/shared/layout/Modal.vue";
import MerchantSolutionSystemForm from "@/components/merchants/solution/MerchantSolutionSystemForm.vue";
import systemTypeToUserText from "@/components/merchants/SystemUtil";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";
import { SystemMerchant } from "@fundraisingbureauet/admin-typescript-client/models/SystemMerchant";
import { SystemType } from "@fundraisingbureauet/admin-typescript-client/models/SystemType";
import { SystemApi } from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({ components: { Modal, MerchantSolutionSystemForm } })
export default class SolutionSystems extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solution!: Solution;

  @Prop({ required: true, default: [] })
  systemMerchants!: Array<SystemMerchant>;

  currentSystemMerchant: SystemMerchant | null = null;

  get solutionsSystemMerchants(): Array<SystemMerchant> {
    //  Prepare list of solution' system merchants
    const solutionsSystemMerchants = [];
    if (this.systemMerchants) {
      for (const availableSystem of this.$root.$data.configurableSystems) {
        //  Flag active system
        let foundSystem = false;

        //  Compare available system against active system merchants
        for (const systemMerchant of this.systemMerchants) {
          if (availableSystem.systemId === systemMerchant.systemType) {
            solutionsSystemMerchants.push(systemMerchant);
            foundSystem = true;
          }
        }

        //  Append inactive system
        if (!foundSystem) {
          const nonSystem = {
            systemType: availableSystem.systemId,
          } as SystemMerchant;
          solutionsSystemMerchants.push(nonSystem);
        }
      }
    }

    return solutionsSystemMerchants;
  }

  private getSystemFields(systemMerchant: SystemMerchant) {
    for (const configurableSystem of this.$root.$data.configurableSystems) {
      if (
        configurableSystem.systemId === systemMerchant.systemType &&
        configurableSystem.fields.length > 0
      ) {
        return configurableSystem.fields;
      }
    }
    return [];
  }

  private getSystemDependencies(systemMerchant: SystemMerchant) {
    const missingSystemDependencies = [];
    let located = false;

    //  Loop available systems
    for (const configurableSystem of this.$root.$data.configurableSystems) {
      //  Select the system being checked, if it has system dependencies
      if (
        configurableSystem.systemId === systemMerchant.systemType &&
        configurableSystem.requiredSystems.length > 0
      ) {
        //  Loop dependent systems
        for (const requiredSystem of configurableSystem.requiredSystems) {
          located = false;

          //  Loop solution's systems to locate those missing
          for (const solutionsSystemMerchant of this.systemMerchants) {
            //  Detect as located
            if (requiredSystem === solutionsSystemMerchant.systemType) {
              located = true;
            }
          }

          //  Register missing dependent system
          if (!located) {
            missingSystemDependencies.push(requiredSystem);
          }
        }

        return missingSystemDependencies;
      }
    }
    return [];
  }

  private getTitleOfSystemDependencies(systemMerchant: SystemMerchant) {
    const systemDependencies = this.getSystemDependencies(systemMerchant);

    if (systemDependencies.length > 0) {
      return "Please register systems: " + systemDependencies.toString();
    }

    return null;
  }

  private getSystemType(systemType: SystemType) {
    return systemTypeToUserText(systemType);
  }

  private createSystemMerchantDirectly(systemMerchant: SystemMerchant) {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: systemMerchant.systemType.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: systemMerchant.systemType as SystemType,
        },
      })
      .subscribe(
        () => {
          //  Toast
          eventHub.$emit("successToast", "System created");

          //  Emit to merchant solution
          this.$emit("updated-system");

          this.closeModal();
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private createSystemMerchant(systemMerchant: SystemMerchant) {
    this.currentSystemMerchant = systemMerchant;
  }

  private updateSystemMerchant(systemMerchant: SystemMerchant) {
    this.currentSystemMerchant = systemMerchant;
  }

  private closeModal() {
    this.currentSystemMerchant = null;
  }

  private updatedSystem() {
    //  Emit to merchant solution
    this.$emit("updated-system");

    this.closeModal();
  }
}
