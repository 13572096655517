
import { Component, Vue } from "vue-property-decorator";
import {
  Product,
  ProductsApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import ComponentProduct from "@/components/products/Product.vue";
import TextInputBlock from "@/components/shared/inputs/TextInputBlock.vue";
import Pagination from "@/components/shared/Pagination.vue";

@Component({
  components: { ComponentProduct, TextInputBlock, Pagination },
})
export default class Products extends Vue {
  products?: Product[] | null = null;
  searchCustomerString = "";
  productsLoading = false;
  productsLoaded = false;
  productsErrored = false;

  get getProducts() {
    return this.products;
  }
  set getProducts(value) {
    this.products = value;
  }

  /**
   * Initialise
   */
  created() {
    this.loadProducts();
  }

  private loadProducts() {
    this.productsLoading = true;

    new ProductsApi(AuthInterceptor.Instance).getProducts().subscribe(
      (response) => {
        this.productsLoading = false;
        this.productsLoaded = true;

        this.getProducts = response;

        console.log(this.getProducts);
      },
      (error) => {
        eventHub.$emit("httpError", error);
        this.productsLoading = false;
        this.productsErrored = true;
        this.productsLoaded = true;
      }
    );
  }
}
