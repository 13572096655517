
import Modal from "@/components/shared/layout/Modal.vue";
import MerchantForm from "@/components/merchants/MerchantForm.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { ModalToolbarSettings } from "@/components/shared/layout/Modal.vue";

@Component({
  components: { Modal, MerchantForm },
})
export default class MerchantModalRegister extends Vue {
  private closeModal() {
    //  Emit to merchant list
    this.$emit("close-modal", false);
  }

  private merchantCreated(merchant: Merchant) {
    //  Emit to merchant list
    this.$emit("merchant-created", merchant);
  }
}
