
import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceDraft } from "@fundraisingbureauet/admin-typescript-client";
import ComponentInvoiceLine from "@/components/invoice/InvoiceLine.vue";
import ComponentInvoiceDraft from "@/components/invoice/InvoiceDraft.vue";
import InvoiceDraftList from "@/components/invoice/InvoiceDraftList.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

@Component({
  components: {
    InvoiceDraftList,
    ComponentInvoiceLine,
    ComponentInvoiceDraft,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class InvoiceDraftListCustomers extends Vue {
  @Prop({ required: false, default: null }) search?: string;
  @Prop({ required: false, default: null }) customer?: Record<string, any>;

  showBody = false;
  colorGreen = "#d0edd4";
  colorRed = "#edd0d0";

  get ShowBody() {
    return this.showBody;
  }

  set ShowBody(value) {
    this.showBody = value;
  }

  get FakturaBackgroundColor() {
    if (this.FakturaAmount != "nothing") {
      if (this.FakturaAmount >= 0) {
        return this.colorGreen;
      }
    }
    return this.colorRed;
  }

  get KreditnotaBackgroundColor() {
    if (this.KreditnotaAmount != "nothing") {
      if (this.KreditnotaAmount >= 0) {
        return this.colorGreen;
      }
    }
    return this.colorRed;
  }

  private formatCurrency(num: number) {
    const formatter = new Intl.NumberFormat("da", {
      style: "currency",
      currency: "DKK",
    });

    return formatter.format(num);
  }

  get FakturaAmount() {
    if (this.customer) {
      if (
        this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 19
        ).length > 0
      ) {
        return this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 19
        )[0].amountExpectedInvoiced;
      }
    }

    return "nothing";
  }

  get KreditnotaAmount() {
    if (this.customer) {
      if (
        this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 24
        ).length > 0
      ) {
        return this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 24
        )[0].amountExpectedInvoiced;
      }
    }

    return "nothing";
  }

  get FakturaStatus() {
    if (this.customer) {
      if (
        this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 19
        ).length > 0
      ) {
        return this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 19
        )[0].state;
      }
    }

    return "nothing";
  }

  get KreditnotaStatus() {
    if (this.customer) {
      if (
        this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 24
        ).length > 0
      ) {
        return this.customer?.invoiceDrafts.filter(
          (draft: InvoiceDraft) => draft.economicLayoutId == 24
        )[0].state;
      }
    }

    return "nothing";
  }
}
